import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import NotFound from './paginas/NoFound';
import Principal from './paginas/Principal';
import Trayectoria from './paginas/Trayectoria';
import Interacciones from './paginas/Interacciones';
import Nosotros from './paginas/Nosotros';
import Layout from './componentes/Layout';


export default function Navegacion (){
    return (
      <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Principal} />
          <Route exact path="/trayectoria" component={Trayectoria} />
          <Route exact path="/interacciones" component={Interacciones} />
          <Route exact path="/nosotros" component={Nosotros} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
      
    );

}


