export const setNegocio = payload =>({
    type: 'SET_NEGOCIO',
    payload,
})

export const setFuncion = payload =>({
    type: 'SET_FUNCION',
    payload,
})

export const setOpcionesR = payload =>({
    type: 'SET_OPCIONES_R',
    payload,
})

export const setOpcionesG = payload =>({
    type: 'SET_OPCIONES_G',
    payload,
})

export const setPedidosAdmin = payload =>({
    type: 'SET_PEDIDOS_ADMIN',
    payload,
})

export const setAdminModo= payload =>({
    type: 'SET_MODO_ADMIN',
    payload,
})


export const setAnalytics = payload =>({
    type: 'SET_ANALYTICS',
    payload,
})







