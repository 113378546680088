import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Navegacion from './Navegacion';
import 'bootstrap/dist/css/bootstrap.min.css';
//import '@zach.codes/react-calendar/dist/calendar-tailwind.css';
//import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import reducer from './reducers'
import {createStore} from 'redux';

const estadoInicial = {
  negocio:"",
  vista:"",
  funcion:"",
  pedidosAdmin:[],
  adminModo:false,
  opcionesRestaurante:{
      entradas:{
        pan:0,
        crepes:0,
        tortillas:0,
        comentarios:""
      },
      fuerte:{
        pasta:0,
        pizza1:0,
        pizza2:0,
        lasagna:0,
        comentarios:""
      },
      bebidas:{
        cafe:0,
        naranja:0,
        comentarios:""
      }
  },
  costosRestaurante:{
    entradas:{
      pan:3,
      crepes:5,
      tortillas:4.5,
    },
    fuerte:{
      pasta:13,
      pizza1:14.5,
      pizza2:15,
      lasagna:18,
    },
    bebidas:{
      cafe:4,
      naranja:5,
    }
},
  opcionesGranizado:{
      alcohol:{
        marcaruya:0,
        blueberry:0,
        smirnoff:0,
        comentarios:""
      },
      sinAlcohol:{
        chicle:0,
        bombombum:0,
        cerezada:0,
        mangobiche:0,
        azucar:0,
        comentarios:""
      }
  },
  analyticsAux: undefined,
}

const store= createStore(reducer, estadoInicial);

ReactDOM.render(
  <Provider store={store}><Navegacion /></Provider>,
    
  document.getElementById('root')
);

