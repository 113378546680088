import React from 'react';
import PropTypes from 'prop-types';
import Links from '../minicomponentes/Links'

const CostoApp = (props) => {
    const { steps } = props;
    const plataforma = steps.plataforma?steps.plataforma.value:'';
    const login = steps.login?steps.login.value:'';
    const internet = steps.internet?steps.internet.value:'';
    const multimedia = steps.multimedia?steps.multimedia.value:'';
    const usuarios = steps.usuarios?steps.usuarios.value:'';
    const localizacion = steps.localizacion?steps.localizacion.value:'';
    const gestion = steps.gestion?steps.gestion.value:'';
    const analytics = steps.analytics?steps.analytics.value:'';
    let valor1=0
    let valor2=0
    let valor3=0
    let valor4=0
    let valor5=0
    let valor6=0
    let valor7=0
    let valor8=0
  
    switch (plataforma) {
        case "IOS":
          valor1=1.2
          break
        case "Android":
          valor1=1
          break
        case 'web':
        valor1=1.1
          break
        case 'Android y Web':
        valor1=2
          break

        case  'multiplataforma movil':
            valor1=2.5
          break
        case  'multiplataforma digital':
          valor1=3
        break
        default:
            valor1=0
          break
      }

      switch (login) {
        case "si":
          valor2=300
          break
        case "no":
          valor2=100
          break
        default:
            valor2=0
          break
      }
      switch (internet) {
        case "si":
            valor3=500
            break
        case "no":
          valor3=200
          break
        case "ambas":
          valor3=600
          break
        default:
            valor3=0
          break
      }
      switch (multimedia) {
        case "si":
            valor4=500
            break
        case "no":
          valor4=100
          break
        default:
            valor4=0
          break
      }
      switch (usuarios) {
        case "si":
            valor5=400
            break
        case "no":
          valor5=50
          break
        default:
            valor5=0
          break
      }
      switch (localizacion) {
        case "mucho":
            valor6=500
            break
        case "poco":
          valor6=300
          break
        case "nunca":
          valor6=50
          break
        default:
            valor6=0
          break
      }
      switch (gestion) {
        case "si":
            valor7=500
            break
        case "no":
          valor7=50
          break
        default:
            valor7=0
          break
      }
      switch (analytics) {
        case "si":
            valor8=100
            break
        case "no":
          valor8=10
          break
        default:
            valor8=0
          break
      }
    const valorf=valor1*(valor1+valor2+valor3+valor4+valor5+valor6+valor7+valor8)
  
    return (
      <div className="test">
        Tu selección fue...
        <br/>
        plataforma(s): {plataforma}
        <br/>
        login: {login}
        <br/> 
        multimedia: {multimedia}
        <br/>
        Diferentes tipos de usuarios: {usuarios}
        <br/> 
        Frecuencia de localizacion: {localizacion}
        <br/> 
        Implementar sistema de gestion: {gestion}
        <br/>
        Implementar Google Analytics: {analytics}
        <br/>
        <br/>
        El precio por tu requerimiento sería desde: <b>${valorf}  USD</b>, su precio podría incrementar en base a la complejidad 
        del aplicativo y las funciones anexas que se requieran
      </div>
    );
  };
  
  CostoApp.propTypes = {
    steps: PropTypes.object,
  };
  
  CostoApp.defaultProps = {
    steps: undefined,
  };


export const aplicativosChat=[
    {
      id: '1',
      message: '¿Qué tipo de plataforma quieres para tu app?',
      trigger: 'plataforma',
      hideInput:true,
    },
    {
        id: 'plataforma',
        options: [
          { value: "IOS", label: 'Solamente en IOS', trigger: '2' },
          { value: "Android", label: 'Solamente en Android', trigger: '2' },
          { value: "web", label: 'Solamente un aplicativo/plataforma web', trigger: '2' },
          { value: "Android y Web", label: 'Aplicativo para Android y para web (Aplican descuentos ✨)', trigger: '2' },
          { value: "multiplataforma movil", label: 'Aplicativo para IOS y para Android (Aplican descuentos ✨)', trigger: '2' },
          { value: "multiplataforma digital ", label: 'Aplicativo para IOS, Android y web(Aplican descuentos ✨)', trigger: '2' },
        ],
        hideInput:true,
      },
    {
      id: '2',
      message: '¿Tu aplicativo requiere Login con Correo, Google y/o Facebook?',
      trigger: 'login',
      hideInput:true,
    },
    {
        id: 'login',
        options: [
          { value: "si", label: 'Si', trigger: '3' },
          { value: "no", label: 'No', trigger: '3' },
        ],
        hideInput:true,
      },
    {
        id: '3',
        message: '¿Tu aplicativo requiere llamar datos de la nube o un servidor o no tiene necesidad de tener acceso a internet?',
        trigger: 'internet',
        hideInput:true,
    },
    {
        id: 'internet',
        options: [
          { value: "si", label: 'Si, necesita tener acceso a ciertos o varios datos almacenados en la nube o un servidor, como mensajes, audio, fotos, historicos, etc.', trigger: '4' },
          { value: "no", label: 'No, todo lo que el usuario necesita se descarga con la instalación del aplicativo.', trigger: '5' },
          { value: "ambas", label: 'Se necesita internet en algunas funciones, pero especialmente será usado sin obligatoriamente estar conectado a la red.', trigger: '4' },
        ],
        hideInput:true,
      },
    {
        id: '4',
        message: '¿El aplicativo requerira guardar multimedia en la nube?',
        trigger: 'multimedia',
        hideInput:true,
    },
    {
        id: 'multimedia',
        options: [
          { value: "si", label: 'Se enviarán y/o recibirán de internet fotos, videos y/o audio.', trigger: '5' },
          { value: "no", label: 'El aplicativo solo enviará y/o recibirá de internet información de texto como, mensajes, localización, comentarios, datos personales y similares.', trigger: '5' },
        ],
        hideInput:true,
      },
    {
        id: '5',
        message: '¿El aplicativo requerirá de varios tipo de usuarios? Administradores/Clientes/Asesores/Premium/....',
        trigger: 'usuarios',
        hideInput:true,
      },
    {
        id: 'usuarios',
        options: [
          { value: "si", label: 'Se requiere distintos tipos de usuarios con distintos roles y funciones dentro de la aplicación.', trigger: '6' },
          { value: "no", label: 'Solo se destinara para un tipo de usuarios o clientes que acceden a todas las funcionalidades del aplicativo.', trigger: '6' },
        ],
        hideInput:true,
     },
     {
      id: '6',
      message: '¿El aplicativo requerirá de acceder a la ubicación del usuario para distintas funcionalidades?',
      trigger: 'localizacion',
      hideInput:true,
    },
    {
        id: 'localizacion',
        options: [
          { value: "mucho", label: 'Se requiere conocer la localización del usuario en varias funciones de la aplicación.', trigger: '7' },
          { value: "poco", label: 'Se requiere conocer la localización del usuario en algunas funciones de la aplicación.', trigger: '7' },
          { value: "nunca", label: 'Bajo ninguna circunstancia se requiere la localización del usuario.', trigger: '7' },
        ],
        hideInput:true,
    },
    {
      id: '7',
      message: '¿El aplicativo requiere varias funciones de gestion para algún tipo de usuario? administrar inventario / dashboard de estadisticas / sumario de clientes y/o productos / registros y/o categorización de chats /...',
      trigger: 'gestion',
      hideInput:true,
    },
    {
      id: 'gestion',
      options: [
        { value: "si", label: 'Se aplican funciones de gestion en la aplicación.', trigger: '8' },
        { value: "no", label: 'No hay sistema de gestión en la aplicación.', trigger: '8' },
      ],
      hideInput:true,
    },
    {
      id: '8',
      message: '¿Requieres integración con Google Analytics? recuerda que esta herramienta te permite llevar trazabilidad de acciones que suceden en tu aplicación y la interacción de los usuarios en ella',
      trigger: 'analytics',
      hideInput:true,
    },
    {
      id: 'analytics',
      options: [
        { value: "si", label: 'Quiero integrar Google Analytics en la app.', trigger: '9' },
        { value: "no", label: 'No es necesario realizar esta integración.', trigger: '9' },
      ],
      hideInput:true,
    },
    {
      id: '9',
      component: <CostoApp />,
      trigger: "10",
      hideInput:true,
    },
    {
      id: '10',
      hideInput:true,
      message: '¿Quieres contactarnos o prefieres realizar otra cotización?, escribe solo la palabra "contactar" o "cotizar".',
      trigger: "contacto",
   },
   {
      id: 'contacto',
      user: true,
      validator: (value) => {
        if (value=="Cotizar"|| value=="cotizar") {
        }
        else if(value=="Contactar" || value=="contactar"){
        }
        else{
          return 'Solo válido: cotizar o contactar';
        }
        return true;
      },
      trigger: ({ value, steps }) => {
        if(value=="contactar" || value=="Contactar")
        {
          return '11'
        }else{
          return '1'
        }}
    },
    {
      id: '11',
      hideInput:true,
      component: <Links />,
      end: true,
    },
  ]