import React from 'react';
import {Button, Container, Row, Col} from "reactstrap"
import {connect} from 'react-redux';

class CustomModal extends React.Component {
  handleRemove = () => {
    console.log(this.props)
    this.props.onRemove();
  }

  handleSave = () => {
    const fullname = this.fullname.value;
    const phone = this.phone.value;
    const servicio = this.servicio.value;
    this.props.onSave({
      fullname,
      phone,
      servicio,
    });
  }


  render() {
    const {
      fullname,
      phone,
      servicio,
      start,
      end,
      actionType
    } = this.props;

    const startHour = start.hour();
    const endHour = end.hour() <= 17 ? end.hour() : 17;

    const duration = endHour - startHour;
    const action = actionType === "create" ? "Apartar" : "Actualizar"

    return (
      <div className="customModal">
        <div className="customModal__text">{`${action} desde ${start.format('HH:mm')} hasta ${end.format('HH:mm')}, por ${duration * 10}$`}</div>
        <div>Escoge el servicio y llena los datos</div>
        <select
          ref={el => this.servicio = el}
          className="customModal__input"
          type="select"
          placeholder="Servicio"
          defaultValue={servicio}
        ><option>Quiero hacer un maniquiur</option>
        <option>Quiero hacer un pediquiur</option>
        <option>Quiero darle estilo a mis cejas</option>
        <option>Quiero un corte de cabello</option>
        <option>Quiero darle estilo a mi barba</option>
        <option>Quiero añadir tintura a mi cabello</option></select>
        {(this.props.admin || action==="Apartar") && <div>
        <input
          ref={el => this.fullname = el}
          className="customModal__input"
          type="text"
          placeholder="Nombre"
          defaultValue={fullname}
        />
        <input
          ref={el => this.phone = el}
          className="customModal__input"
          type="text"
          placeholder="Numero celular"
          defaultValue={phone}
        />
        <Container>
            <Row>
                <Col>
                <Button color="secondary" size="lg" style={{background:"linear-gradient(to right, #25e1d4,#3dc28f)"}} onClick={this.handleSave}>{action}</Button>
                </Col>
                <Col>
                {this.props.admin && <Button color="secondary" size="lg"  onClick={this.handleRemove}>Quitar reserva</Button>}
                </Col>
            </Row>
        </Container>
        </div>}
        
        
      </div>
    );
  }
}

const mapStateToProps = state => {
    return{
      admin:state.adminModo,
    }
    
  }
  
  export default connect(mapStateToProps, null)(CustomModal);