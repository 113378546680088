import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Links from '../minicomponentes/Links'

const CostoTraducir = (props) => {
    const { steps } = props;
    const idiomaInicio = steps.idiomaInicio.value;
    const idiomaFin = steps.idiomaFin.value;
    const framework = steps.framework.value;
    let valor1=0
    let valor2=0
    let valor3=0
  
    switch (framework) {
        case "Flutter":
          valor1=8
          break
        case "Java":
          valor1=7
          break
        case 'kotlin':
        valor1=6
          break

        case  'React JS':
            valor1=5
          break
        default:
            valor1=0
          break
      }

      switch (idiomaFin) {
        case "Español":
            valor2=4
            break
        case "Portugues":
          valor2=5
          break
        case "Ingles":
          valor2=6
          break
        case 'Alemán':
        valor2=9
          break
        default:
            valor2=0
          break
      }
      switch (idiomaInicio) {
        case "Español":
            valor3=4
            break
        case "Portugues":
          valor3=5
          break
        case "Ingles":
          valor3=6
          break
        case 'Alemán':
        valor3=9
          break
        default:
            valor3=0
          break
      }
    const valorf=valor1+valor3+valor2
  
    return (
      <div className="test">
        Tu selección fue: el framework/lenguaje {framework}, con traducción del {idiomaInicio} a {idiomaFin}
        <br/>
        El precio por tu requerimiento sería desde: ${valorf} USD/ hora
      </div>
    );
  };
  
  CostoTraducir.propTypes = {
    steps: PropTypes.object,
  };
  
  CostoTraducir.defaultProps = {
    steps: undefined,
  };


  

export const traducirChat=[
    {
      id: '1',
      hideInput:true,
      message: '¿Qué framework o lenguaje manejas en tu aplicación?',
      trigger: 'framework',
    },
    {
        id: 'framework',
        hideInput:true,
        options: [
          { value: 'kotlin', label: 'kotlin', trigger: '2' },
          { value: 'Java', label: 'Java', trigger: '2' },
          { value: 'Flutter', label: 'Flutter', trigger: '2' },
          { value: 'React JS', label: 'React JS', trigger: '2' },
        ],
      },
    {
        id: '2',
        hideInput:true,
        message: 'Deseas traducir un app que está en idioma ...',
        trigger: "idiomaInicio"
    },
    {
        id: 'idiomaInicio',
        hideInput:true,
        options: [
          { value: 'Español', label: 'Español', trigger: '3' },
          { value: 'Portugues', label: 'Portugues', trigger: '3' },
          { value: 'Ingles', label: 'Ingles', trigger: '3' },
          { value: 'Alemán', label: 'Alemán', trigger: '3' },
        ],
      },
      {
        id: '3',
        hideInput:true,
        message: 'Tu app está en el idioma {previousValue}, ahora selecciona el idioma en el que se quiere traducir ...',
        trigger: "idiomaFin"
    },
    {
        id: 'idiomaFin',
        hideInput:true,
        options: [
          { value: 'Español', label: 'Español', trigger: '4' },
          { value: 'Portugues', label: 'Portugues', trigger: '4' },
          { value: 'Ingles', label: 'Ingles', trigger: '4' },
          { value: 'Alemán', label: 'Alemán', trigger: '4' },
        ],
      },
    {
        id: '4',
        hideInput:true,
        component: <CostoTraducir />,
        trigger: "5"
      },
      {
        id: '5',
        hideInput:true,
        message: '¿Quieres contactarnos o prefieres realizar otra cotización?, escribe solo la palabra "contactar" o "cotizar".',
        trigger: "contacto",
     },
     {
        id: 'contacto',
        user: true,
        validator: (value) => {
          if (value=="Cotizar"|| value=="cotizar") {
          }
          else if(value=="Contactar" || value=="contactar"){
          }
          else{
            return 'Solo válido: cotizar o contactar';
          }
          return true;
        },
        trigger: ({ value, steps }) => {
          if(value=="contactar" || value=="Contactar")
          {
            return '6'
          }else{
            return '1'
          }}
      },
      {
        id: '6',
        hideInput:true,
        component: <Links />,
        end: true,
      },

  ]