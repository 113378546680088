import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Links from '../minicomponentes/Links'

const CostoMarketing = (props) => {
    const { steps } = props;
    const contenido1 = steps.contenido1?steps.contenido1.value:'';
    const contenido2 = steps.contenido2?steps.contenido2.value:'';
    const marketing = steps.plataforma?steps.plataforma.value:'';
    let valor1=0
    let valor2=0
    let valor3=0
  
    switch (contenido2) {
        case "redes sociales":
          valor1=8
          break
        case "publicidad":
          valor1=7
          break
        case 'empresarial':
        valor1=6
          break
        case 'variados tipos':
        valor1=17
          break

        case  'otro tipo':
            valor1=6
          break
        default:
            valor1=0
          break
      }

      switch (contenido1) {
        case "redes sociales":
          valor2=10
          break
        case "publicidad":
          valor2=9
          break
        case 'empresarial':
        valor2=8
          break

        case 'variados tipos':
        valor2=22
          break

        case  '-':
            valor2=0
          break
        default:
            valor2=0
          break
      }
      switch (marketing) {
        case "redes sociales":
            valor3=4
            break
        case "Google":
          valor3=5
          break
        case "Google y Facebook":
          valor3=7
          break
        default:
            valor3=0
          break
      }
    const valorf=valor1+valor3+valor2
  
    return (
      <div className="test">
        Tu selección fue...
        <br/>
        Marketing digital de tipo: {marketing}
        <br/>
        Contenido digital a fin hacia: {contenido1}{contenido2}
        <br/>
        El precio por tu requerimiento sería desde: ${valorf} USD/ hora
      </div>
    );
  };
  
  CostoMarketing.propTypes = {
    steps: PropTypes.object,
  };
  
  CostoMarketing.defaultProps = {
    steps: undefined,
  };



export const marketingChat=[
    {
      id: '1',
      hideInput:true,
      message: '¿Estás interesado en que te colaboremos con la creación de contenido digital (Clips, imagenes, GIF, Stickers) o con el acompañamiento y ejecución de un plan de marketing digital?',
      trigger: 'afin',
    },
    {
        id: 'afin',
        hideInput:true,
        options: [
          { value: "marketing", label: 'Acompañamiento y ejecución de un plan de marketing digital', trigger: '2' },
          { value: "contenido", label: 'Creación de contenido digital', trigger: '4' },
          { value: "ambas", label: 'Tanto en el marketing digital como en la creación de contenido (Aplican descuentos ✨)', trigger: '2' },
        ],
      },
    {
      id: '2',
      hideInput:true,
      message: 'Tenemos una variada opción para acompañarte en las pautas de redes sociales y google, ¿En cúal estarías interesad@?',
      trigger: 'plataforma',
    },
    {
        id: 'plataforma',
        hideInput:true,
        options: [
          { value: "redes sociales", label: 'Pautar en redes sociales (Facebook, Instagram y Facebook Ads Network)', trigger: '3' },
          { value: "Google", label: 'Pautar en Google (Youtube, Motor de Busqueda, AdSense)', trigger: '3' },
          { value: "Google y Facebook", label: 'Pautar en ambas plataformas (Aplican descuentos ✨)', trigger: '3' },
        ],
      },
    {
        id: '3',
        hideInput:true,
        message: 'Podemos crear contenido digital a la medida de tu negocio en diferentes formatos (Clips, imagenes, GIF, Stickers), ¿con que tipo de contenido estarías afin?',
        trigger: 'contenido1',
    },
    {
        id: 'contenido1',
        hideInput:true,
        options: [
          { value: "redes sociales", label: 'Contenido para redes sociales', trigger: 'costo' },
          { value: "publicidad", label: 'Contenido para anuncios de publicidad', trigger: 'costo' },
          { value: "empresarial", label: 'Contenido para uso empresarial (logo, iconografía, membretes, volantes)', trigger: 'costo' },
          { value: "variados tipos", label: 'Contenido empresarial + publicidad + redes sociales (Aplican descuentos ✨)', trigger: 'costo' },
          { value: "-", label: 'No estoy interesado en contenido', trigger: 'costo' },
        ],
      },
    {
        id: '4',
        hideInput:true,
        message: 'Podemos crear contenido digital a la medida de tu negocio en diferentes formatos (Clips, imagenes, GIF, Stickers), ¿con qué tipo de contenido estarías afin?',
        trigger: 'contenido2',
    },
    {
        id: 'contenido2',
        hideInput:true,
        options: [
          { value: "redes sociales", label: 'Contenido para redes sociales', trigger: 'costo' },
          { value: "publicidad", label: 'Contenido para anuncios de publicidad', trigger: 'costo' },
          { value: "empresarial", label: 'Contenido para uso empresarial (logo, iconografía, membretes, volantes)', trigger: 'costo' },
          { value: "variados tipos", label: 'Contenido empresarial + publicidad + redes sociales (Aplican descuentos ✨)', trigger: 'costo' },
          { value: "otro tipo", label: 'Contenido para otro uso', trigger: 'costo' },
        ],
      },
    {
        id: 'costo',
        hideInput:true,
        component: <CostoMarketing />,
        trigger: "5"
      },
    {
        id: '5',
        hideInput:true,
        message: '¿Quieres contactarnos o prefieres realizar otra cotización?, escribe solo la palabra "contactar" o "cotizar".',
        trigger: "contacto",
     },
     {
        id: 'contacto',
        user: true,
        validator: (value) => {
          if (value=="Cotizar"|| value=="cotizar") {
          }
          else if(value=="Contactar" || value=="contactar"){
          }
          else{
            return 'Solo válido: cotizar o contactar';
          }
          return true;
        },
        trigger: ({ value, steps }) => {
          if(value=="contactar" || value=="Contactar")
          {
            return '6'
          }else{
            return '1'
          }}
      },
      {
        id: '6',
        hideInput:true,
        component: <Links />,
        end: true,
      },
  ]