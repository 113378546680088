import React from 'react';

const customDayCell = (props) => {
  const hour = props.startTime.hour();
  if (hour < 17 && hour>12 || hour < 12 && hour>9) {
    return (<div style={{color: "green", marginLeft:"5%"}} onMouseDown={props.startSelection}>-</div>);
  }

  return (<div style={{backgroundColor: "gray", color: "#fff" , paddingLeft:"5%"}}>Cerrado</div>);
}


export default customDayCell;