import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { Container, Col, Row,  ButtonGroup, Button, ButtonToolbar, Card} from 'reactstrap';
import {
    faWhatsapp,faTelegram,faLinkedin
} from "@fortawesome/free-brands-svg-icons"
import {
} from "@fortawesome/free-regular-svg-icons"
import {
    faMailBulk
} from "@fortawesome/free-solid-svg-icons"
import React from 'react';
import PropTypes from 'prop-types';

export default function Links(props){
const { steps } = props;
return (
    <div className="test">
    <Container fluid >
                <Row>
                    <Col>
                    ¿Es de tu interés?, A continuación anexamos nuestros canales de contacto para más información, recuerda copiar y pegar el sumario de tu cotización en el mensaje:
                    </Col>
                </Row>
                <Row>
                
                <Col xs="6" md={{ size: '6', offset: 0 }} lg={{ size: '6', offset: 0 }}>
                <div >            
                <ButtonToolbar>
                    <ButtonGroup>
                    <a href={`https://api.whatsapp.com/send?phone=573028468860&text="Hola. Estoy interesado en obtener un plan de transformación digital para mi negocio..."`} target="_blank" rel="noreferrer">
                        <Button outline  color="secondary">
                            <FontAwesomeIcon icon={faWhatsapp} size="2x"/>
                        </Button>
                    </a>
                    <a href="https://t.me/bifrost_apps" target="_blank" rel="noreferrer" >
                        <Button outline  color="secondary">
                            <FontAwesomeIcon icon={faTelegram} size="2x"/>
                        </Button>
                    </a>
                    {/* <a href="https://www.instagram.com/rosegold.ice/" target="_blank" rel="noreferrer" >
                        <Button outline  color="secondary">
                            <FontAwesomeIcon icon={faLinkedin} size="2x"/>
                        </Button>
                    </a> */}
                    <a href="mailto:bifrost.aplicaciones@gmail.com?subject=Plan de transformación digital&body=Hola. Estoy interesado en obtener un plan de transformación digital para mi negocio..." target="_blank" rel="noreferrer" >
                        <Button outline  color="secondary">
                            <FontAwesomeIcon icon={faMailBulk} size="2x"/>
                        </Button>
                    </a>
                    </ButtonGroup>
                    </ButtonToolbar>
                </div >
                </Col >
                </Row>         
            </Container>
    </div>
);
};

Links.propTypes = {
steps: PropTypes.object,
};

Links.defaultProps = {
steps: undefined,
};
