import "../estilos/Principal.css"
import classnames from 'classnames';
import React, { useState } from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, Alert, CardTitle, CardText, UncontrolledCarousel ,Container, Row, Col, Jumbotron
} from 'reactstrap';

const itemsRose = [
  {
    src: process.env.PUBLIC_URL + '/assets/rose1.png',
    key: '1'
  },
  {
    src:  process.env.PUBLIC_URL + '/assets/rose2.png',
    key: '2'
  },
];

const itemsRifaya = [
  {
    src:  process.env.PUBLIC_URL + '/assets/rifaya3.png',
    key: '1'
  },
  {
    src:  process.env.PUBLIC_URL + '/assets/rifaya2.png',
    key: '2'
  },
  {
    src:  process.env.PUBLIC_URL + '/assets/rifaya1.png',
    key: '5'
  }
];

const itemsCondu = [
  {
    src:  process.env.PUBLIC_URL + '/assets/conductores3.png',
    key: '7'
  },
];

function Trayectoria (props)  {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div>
      <br></br>
      <Container>
        <Row>
          <Col xs={{ size: 12, offset: 0 }}>

          <h1 className="titulote">Nuestra Experiencia</h1>
          </Col>
        </Row>
      </Container>
      <br></br>
      <Container fluid >
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            style={{height:"12vh",width:"30vw"}}
            onClick={() => { toggle('1'); }}
          >
            <Container><Row><Col ><div className="dot-online"></div></Col></Row><Row><Col><p className="text-descrip">RifaYa </p></Col ></Row></Container>          

          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
          style={{height:"12vh",width:"30vw", }}
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
           <Container><Row><Col ><div className="dot-online"></div></Col></Row><Row><Col><p className="text-descrip">Rose Gold Ice </p></Col ></Row></Container>  
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            style={{height:"12vh",width:"30vw"}}
            onClick={() => { toggle('3'); }}
          >
           <Container><Row><Col ><div className="dot-pendiente"></div></Col></Row><Row><Col><p className="text-descrip">Conducir App </p></Col ></Row></Container>          
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>


        <TabPane tabId="1">
          
      <br></br>
      <UncontrolledCarousel items={itemsRifaya} />
        <br/>
          <Row>
          <Col sm="12">
          <Jumbotron style={{background: "#dee7d2"}} id="descripcion">
            <Container>
              <Row>
                <Col>
                
                  <h5 className="titulote2">RifaYa - Talonario virtual de rifas y sorteos</h5>
                  <p className="lead">Es una app multiplataforma que permite a las personas y/o negocios crear, administrar y compartir sus rifas, sorteos o concursos.</p>
                  <hr className="my-2" />
                  <p>Para saber más click en alguno de los siguientes botones:</p>
                  
                  <Container>        
                      <Row>
                        <Col xs={{size:"6",offset:"1"}} sm={{size:"6",offset:"0"}}>
                          <Button outline color="secondary" className="boton-sombra1"><a href="https://rifaya-app.online" target="_blank" ><img width="200vh" src="https://www.rifaya-app.online/logo2.png"/></a></Button>                   
                        </Col>                  
                        <Col xs={{size:"6",offset:"1"}} sm={{size:"6",offset:"0"}}>
                          <Button outline color="secondary" className="boton-sombra1"><a href="https://play.google.com/store/apps/details?id=com.bifrost.rifaya" target="_blank" rel="noreferrer" ><img width="200vh" src={process.env.PUBLIC_URL + '/assets/googlePlay.png'}/></a></Button>                   
                        </Col>
                      </Row>
                      <br/>
                 </Container> 
                </Col>
                </Row>
                <Row>
                <Col>
                  <Card body style={{marginLeft:"2vw"}}>
                    <CardTitle className="text-descrip">Funciones y características</CardTitle>
                    
                    <Alert color="secondary">
                    Ingresa la lotería nacional con la que juega tu rifa - sorteo.</Alert>
                    <Alert color="secondary">
                    Elige el día que va a jugar tu rifa, concurso o sorteo.</Alert>
                    <Alert color="secondary">
                    Puede crear talonarios de 100 números o rifas de 2 o 3 cifras.</Alert>
                    <Alert color="secondary">
                    Puedes adquirir las boletas como administrador usando la App o mediante el link de la rifa, los participantes pueden hacerlo por ellos mismos.</Alert>
                    <Alert color="secondary">
                    Cuando te paguen las boletas puedes marcarlas como pagadas. Siempre lleva el control fácilmente y en tiempo real.</Alert>
                    <Alert color="secondary">
                    Visualiza la información del poseedor (participante) de cada boleta, puedes ver su información como el nombre, número celular, correo electrónico, estado de la boleta (Pagada o En deuda), el método de pago que usaron para pagarte la boleta.</Alert>
                    <Alert color="secondary">
                    Si no te pagaron la boleta puedes liberar la boleta para que pueda volver a estar disponible para otro participante y así logres vender todas las boletas de tu rifa - sorteo.</Alert>
                    <Alert color="secondary">
                    Cuando sea el día del sorteo puedes introducir la boleta ganadora y se visualizará en el link de tu rifa para que los participantes puedan entrar a verificar si su boleta fue la ganadora del premio.</Alert>
                    <Alert color="secondary">
                    Puedes comenzar una nueva rifa cuando lo desees, puedes crear hasta 3 rifas al mismo tiempo y administrarlas todas mediante la App en simultaneo y hasta cancelarlas si algo sale mal.</Alert>
                    <Alert color="secondary">
                    Genera una lista de boletas con todos los participantes que han adquirido boletas, puedes organizar en orden alfabético por el nombre del participante, de menor a mayor número de boleta o incluso organizar con base en el estado de la boleta, pagada o en deuda.</Alert>
                    <Alert color="secondary">
                    Personaliza un mensaje en tu talonario para que aparezca en el link de tu rifa, puedes poner información del pago de la boleta, información adicional sobre el premio o incluso la razón de por que haces la rifa.</Alert>
                    <Alert color="secondary">
                    Libérate de los anuncios, tanto en el link de tus rifas como en la App. Gestiona tu rifa sin necesidad de ver ni un solo anuncio.</Alert>
                    <Alert color="secondary">
                    Pon una foto o imagen del premio en el link de tu rifa para que los participantes se animen aún más a adquirir boletas.</Alert>
                    <Alert color="secondary">
                    Si no alcanzaste a vender todas las boletas podemos cambiar la fecha de tu rifa - sorteo para que puedas tener más tiempo para vender todas las boletas y maximices tus ganancias.</Alert>
                    <Alert color="secondary">
                    Rifas personalizadas, haz que tu talonario virtual sea más llamativo y único para los participantes, podemos poner el logo de tu marca, los colores que desees, los links a tus redes sociales, quitamos todos los anuncios y hasta ponemos la foto del premio que estas sorteando.</Alert>
                    <br/>
                  </Card>
                </Col>
              </Row>
            </Container>   
          </Jumbotron>
            </Col>
          </Row>
          <br/>
        </TabPane>


        <TabPane tabId="2">
          
      <br></br>
      <UncontrolledCarousel items={itemsRose} />
        <br/>
          <Row>
          <Col sm="12">
          <Jumbotron style={{background: "#dee7d2"}} id="descripcion">
            <Container>
              <Row>
                <Col>
                
                  <h5 className="titulote2">Rose Gold Ice - aplicación web para compartir con familia y amigos</h5>
                  <p className="lead">Es una app web diseñada para un producto comestible, donde al adquirirse se brinda al cliente un código único para ingresar al sitio e interactuar con juegos y actividades distintas.</p>
                  <hr className="my-2" />
                  <p>Para saber más click en el siguiente boton:</p>
                  
                  <Container>        
                      <Row>
                        <Col xs={{size:"6",offset:"1"}} sm={{size:"10",offset:"2"}}>
                        <Button outline color="secondary" className="boton-sombra1"><a href="https://rose-gold-ice.web.app/" target="_blank"><img width="200vh" src="https://rose-gold-ice.web.app/assets/logoRoseGoldIce.png"/></a></Button>                         
                        </Col> 
                      </Row>
                      <br/>
                 </Container> 
                </Col>
                <Col>
                  <Card body style={{marginLeft:"2vw"}}>
                    <CardTitle className="text-descrip">Funciones y características</CardTitle>
                    
                    <Alert color="secondary">
                    Ingreso con código único, el cual tiene una caducidad específicada desde la base de datos</Alert>
                    <Alert color="secondary">
                    Página informativa del producto y menú.</Alert>
                    <Alert color="secondary">
                    Juegos interactivos como, charadas, adivinanzas, dados, botellita y ruleta de recuerdos </Alert>
                    <Alert color="secondary">
                    Espacio para recomendaciones y sugerencias.</Alert>
                    <Alert color="secondary">
                    Espacio para mensaje personalizado al cliente.</Alert>
                    <Alert color="secondary">
                    Links a las redes sociales del negocio.</Alert>
                    <br/>
                  </Card>
                </Col>
              </Row>
            </Container>   
          </Jumbotron>
            </Col>
          </Row>
          <br/>
        </TabPane>


        <TabPane tabId="3">
          
      <br></br>
      <UncontrolledCarousel items={itemsCondu} />
        <br/>
        <Jumbotron style={{background: "#dee7d2"}} id="descripcion">
            <Container>
              <Row>
                <Col>
                
                  <h5 className="titulote2">App móvil multiplataforma para conductores.</h5>
                  <hr className="my-2" />
                  
                  <Alert color="light">
                    Ésta información no se encuentra disponible al público, ya que la aplicación está en su fase de desarrollo.</Alert>
                    <Alert color="light">
                    Prontamente disponible para dispositivos Android y IOS.</Alert>
                    <br/>
                </Col>
              </Row>
            </Container>   
          </Jumbotron>
          <br/>
        </TabPane>
      </TabContent>
      </Container>
      
    </div>
  );
}

export default Trayectoria;