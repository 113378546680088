import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { Container, Col, Row,  ButtonGroup, Button, FormGroup, Label, List, Input , UncontrolledCarousel} from 'reactstrap';
import {
    faWhatsapp,faTelegram,faLinkedin
} from "@fortawesome/free-brands-svg-icons"
import {
} from "@fortawesome/free-regular-svg-icons"
import {
    faMailBulk
} from "@fortawesome/free-solid-svg-icons"
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setOpcionesR, setOpcionesG} from '../actions/index';




function ObjetoNegocio (props){
  
  const { steps } = props;
  const tipoNegocio=props.negocioEntrante
  const tipoChat=props.funcionChat
  const eleccion1=steps.entrada1?steps.entrada1.value:"-"

  let visualizarEleccion

 

  
  const opcionado=(e)=>{
    console.log(e.target.value,e.target.name)
    const valor=e.target.name==="comentario"?e.target.value:parseInt(e.target.value)
    switch (eleccion1){
      case "entradas":
        switch(e.target.name){
          case "opcion1":
            props.setOpcionesR({...props.opcionesR,[eleccion1]:{...props.opcionesR.entradas,pan:valor}})
            break
          case "opcion2":
            props.setOpcionesR({...props.opcionesR,[eleccion1]:{...props.opcionesR.entradas, tortillas:valor}})
            break
          case "opcion3":
            props.setOpcionesR({...props.opcionesR,[eleccion1]:{...props.opcionesR.entradas,crepes:valor}})
            break
          case "comentario":
            props.setOpcionesR({...props.opcionesR,[eleccion1]:{...props.opcionesR.entradas,comentarios:valor}})
            break
        }
        break
      case "plato fuerte":
        switch(e.target.name){
          case "opcion1":
            props.setOpcionesR({...props.opcionesR,"fuerte":{...props.opcionesR.fuerte,pasta:valor}})
            break
          case "opcion2":
            props.setOpcionesR({...props.opcionesR,"fuerte":{...props.opcionesR.fuerte, pizza1:valor}})
            break
          case "opcion3":
            props.setOpcionesR({...props.opcionesR,"fuerte":{...props.opcionesR.fuerte,pizza2:valor}})
            break
          case "opcion4":
            props.setOpcionesR({...props.opcionesR,"fuerte":{...props.opcionesR.fuerte,lasagna:valor}})
            break
          case "comentario":
            props.setOpcionesR({...props.opcionesR,"fuerte":{...props.opcionesR.fuerte,comentarios:valor}})
            break
        }
        break

        case "bebidas":
          switch(e.target.name){
            case "opcion1":
              props.setOpcionesR({...props.opcionesR,"bebidas":{...props.opcionesR.bebidas,cafe:valor}})
              break
            case "opcion2":
              props.setOpcionesR({...props.opcionesR,"bebidas":{...props.opcionesR.bebidas, naranja:valor}})
              break
            case "comentario":
              props.setOpcionesR({...props.opcionesR,"bebidas":{...props.opcionesR.bebidas,comentarios:valor}})
              break
          }
          break

      
    }
  }

  const sumario=()=>{
    props.triggerNextStep(()=>{console.log("click hecho")})
  }

  const itemsEntradas=[
    {
      src: process.env.PUBLIC_URL + '/assets/restaurante/pan.jpg',
      key: '1'
    },
    {
      src:  process.env.PUBLIC_URL + '/assets/restaurante/crepes.jpg',
      key: '2'
    },
    {
      src:  process.env.PUBLIC_URL + '/assets/restaurante/tortillas.jpg',
      key: '3'
    }
  ];

  const itemsFuertes=[
    {
      src: process.env.PUBLIC_URL + '/assets/restaurante/lasagna.jpg',
      key: '1'
    },
    {
      src:  process.env.PUBLIC_URL + '/assets/restaurante/pasta.jpg',
      key: '2'
    },
    {
      src:  process.env.PUBLIC_URL + '/assets/restaurante/pizza-1.jpg',
      key: '3'
    },
    {
      src:  process.env.PUBLIC_URL + '/assets/restaurante/pizza-2.jpg',
      key: '4'
    }
  ];

  const itemsBebidas=[
    {
      src: process.env.PUBLIC_URL + '/assets/restaurante/cafe.jpg',
      key: '1'
    },
    {
      src:  process.env.PUBLIC_URL + '/assets/restaurante/jugoNaranja.jpg',
      key: '2'
    },
  ];

  switch(eleccion1){
      case "entradas":
        visualizarEleccion=<div><UncontrolledCarousel items={itemsEntradas} />
         <List type="unstyled">
          <li>
            <ul>              
              <li>Exquisito pan preparado con trigo y avena - $3 
                <Input
                  type="number"
                  name="opcion1"
                  value={props.opcionesR.entradas.pan}
                  onChange={opcionado}
                /></li>
              <li>Deliciosas tortillas hechas a fuego lento - $4.5</li> 
                <Input
                  type="number"
                  name="opcion2"
                  value={props.opcionesR.entradas.tortillas}
                  onChange={opcionado}
                />
              <li>Espectaculares crepes elaborados por las mejores manos - $5</li>
                <Input
                    type="number"
                    name="opcion3"
                    value={props.opcionesR.entradas.crepes}
                    onChange={opcionado}
                  />
            </ul>
          </li>
          <li><FormGroup>
            <Label for="textoComentario">Si quieres agregar comentarios, puedes escribir a continuación...</Label>
            <Input type="textarea" name="comentario" id="textoComentario" onChange={opcionado} value={props.opcionesR.entradas.comentarios}/>
          </FormGroup>
          <Button outline color="secondary" onClick={sumario}>Confirmar</Button></li>
        </List>
        </div>
        break
      case "plato fuerte":
        visualizarEleccion=<div><UncontrolledCarousel items={itemsFuertes} />
        <List type="unstyled">
          <li>
            <ul>              
              <li>Exquisita pasta con salsa de la casa - $13 
                <Input
                  type="number"
                  name="opcion1"
                  value={props.opcionesR.fuerte.pasta}
                  onChange={opcionado}
                /></li>
              <li>Deliciosas pizza mediterranea hecha en leña - $14.5</li> 
                <Input
                  type="number"
                  name="opcion2"
                  value={props.opcionesR.fuerte.pizza1}
                  onChange={opcionado}
                />
              <li>Espectacular pizza huerta sembrada con el máximo cariño - $15</li>
                <Input
                    type="number"
                    name="opcion3"
                    value={props.opcionesR.fuerte.pizza2}
                    onChange={opcionado}
                  />
              <li>Increible lasagna del ingrediente secreto, con la majestuacidad de un gran clásico - $18</li>
                <Input
                    type="number"
                    name="opcion4"
                    value={props.opcionesR.fuerte.lasagna}
                    onChange={opcionado}
                  />
            </ul>
          </li>
          <li><FormGroup>
            <Label for="textoComentario">Si quieres agregar comentarios, puedes escribir a continuación...</Label>
            <Input type="textarea" name="comentario" id="textoComentario" onChange={opcionado} value={props.opcionesR.fuerte.comentarios}/>
          </FormGroup>
          <Button outline color="secondary" onClick={sumario}>Confirmar</Button></li>
        </List>
        </div>
        break
      case "bebidas":
        visualizarEleccion=<div><UncontrolledCarousel items={itemsBebidas} />
         <List type="unstyled">
          <li>
            <ul>              
              <li>Refrescante jugo de naranja recargado - $5
                <Input
                  type="number"
                  name="opcion1"
                  value={props.opcionesR.bebidas.cafe}
                  onChange={opcionado}
                /></li>
              <li>Un americano para los frios de la noche - $4</li> 
                <Input
                  type="number"
                  name="opcion2"
                  value={props.opcionesR.bebidas.naranja}
                  onChange={opcionado}
                />
            </ul>
          </li>
          <li><FormGroup>
            <Label for="textoComentario">Si quieres agregar comentarios, puedes escribir a continuación...</Label>
            <Input type="textarea" name="comentario" id="textoComentario" onChange={opcionado} value={props.opcionesR.bebidas.comentarios}/>
          </FormGroup>
          <Button outline color="secondary" onClick={sumario}>Confirmar</Button></li>
        </List>
        </div>
        break
      case "desayuno":
        visualizarEleccion=<button>al deayuno</button>
        break
      case "cumple":
        visualizarEleccion=<button>al cumple</button>
        break
      case "ellas":
        visualizarEleccion=<button>pa ellas</button>
        break
      case "ellos":
        visualizarEleccion=<button>pa ellos</button>
        break
      case "otro":
        visualizarEleccion=<button>algo original</button>
        break
      default:
        visualizarEleccion=<button>----</button>
        break
  }


  const restaurante=
        <div className="test">
          {visualizarEleccion}          
        </div>

  const cajas=
        <div className="test">
          No disponible
        </div>

  const odontologo=
        <div className="test">
          No disponible
        </div>

  const peluqueria=
        <div className="test">
          
          
        </div>

  switch(tipoChat){
    case "vendedor":
      return (
        tipoNegocio==="negocio1"? cajas:restaurante
      );
    case "agenda":
      return (
        tipoNegocio==="negocio1"? odontologo:peluqueria
      );
    default:
      return (
        "--"
      );
    
  }

    
  };
  
  ObjetoNegocio.propTypes = {
    steps: PropTypes.object,
  };
  
  ObjetoNegocio.defaultProps = {
    steps: undefined,
  };



const mapStateToProps = state => {
    return{
        negocioEntrante:state.negocio,
        funcionChat:state.funcion,
        opcionesR:state.opcionesRestaurante,
        opcionesG:state.opcionesGranizado,

    }

}

const mapDispatchToProps = {
  setOpcionesG,
  setOpcionesR
}
  

export default connect(mapStateToProps, mapDispatchToProps)(ObjetoNegocio);
  