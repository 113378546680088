import React, {useState} from 'react';
import {
  Card, Button, CardImg, CardTitle, CardText, CardDeck,
  CardSubtitle, CardBody, Container, Row, Col
} from 'reactstrap';
import "../estilos/servicios.css"

export default function Servicios(props){

    const [botDescripcion, setBotDescripcion]=useState(false)
    const [traductorDescripcion, setTraductorDescripcion]=useState(false)
    const [formDescripcion, setFormDescripcion]=useState(false)
    const [marketDescripcion, setMarketDescripcion]=useState(false)
    const [appsDescripcion, setAppsDescripcion]=useState(true)
    const [pedidoDescripcion, setPedidoDescripcion]=useState(false)

    const estiloBoton={background:"linear-gradient(to right, #25e1d4,#3dc28f)"}
    const estiloFondoCarta={background: "#dee7d2"}

    const abrirDescripcionBot=()=>{
        setBotDescripcion(true)
        setTraductorDescripcion(false)
        setFormDescripcion(false)
        setMarketDescripcion(false)
        setAppsDescripcion(false)
        setPedidoDescripcion(false)
    }

    const abrirDescripcionTraductor=()=>{
        setBotDescripcion(false)
        setTraductorDescripcion(true)
        setFormDescripcion(false)
        setMarketDescripcion(false)
        setAppsDescripcion(false)
        setPedidoDescripcion(false)
    }

    const abrirDescripcionForm=()=>{
        setBotDescripcion(false)
        setTraductorDescripcion(false)
        setFormDescripcion(true)
        setMarketDescripcion(false)
        setAppsDescripcion(false)
        setPedidoDescripcion(false)
    }

    const abrirDescripcionMarket=()=>{
        setBotDescripcion(false)
        setTraductorDescripcion(false)
        setFormDescripcion(false)
        setMarketDescripcion(true)
        setAppsDescripcion(false)
        setPedidoDescripcion(false)
    }

    const abrirDescripcionApps=()=>{
        setBotDescripcion(false)
        setTraductorDescripcion(false)
        setFormDescripcion(false)
        setMarketDescripcion(false)
        setAppsDescripcion(true)
        setPedidoDescripcion(false)
    }

    const abrirDescripcionPedidos=()=>{
        setBotDescripcion(false)
        setTraductorDescripcion(false)
        setFormDescripcion(false)
        setMarketDescripcion(false)
        setAppsDescripcion(false)
        setPedidoDescripcion(true)
    }

    return(
        <Container>
            <Row>
                <Col>
                <CardDeck>
                    {!marketDescripcion &&<Button onClick={abrirDescripcionMarket} color="light" className="boton-sombra">
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Marketing digital <br/> Creación de contenido</CardSubtitle>
                        <img  width={"250vw"} src="https://media1.tenor.com/images/e54db7b0fe28ba720ed75b15a3fbcb94/tenor.gif?itemid=8677396"/>
                        <CardSubtitle style={{fontSize:"8px"}} className="mb-2 text-muted"><a href="https://tenor.com/view/marketing-mercadeo-marketero-gif-8677396">GIF</a> de <a href="https://tenor.com/search/marketing-gifs">tenor.com</a></CardSubtitle>
                    </Button>}
                    {marketDescripcion && <Card style={estiloFondoCarta}>
                        <CardImg top width="100%" src="https://media1.tenor.com/images/e54db7b0fe28ba720ed75b15a3fbcb94/tenor.gif?itemid=8677396" alt="Card image cap" />
                        <CardBody>
                        <CardTitle tag="h5">Marketing digital</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Redes sociales y Google Ads / Creación de contenido</CardSubtitle>
                        <CardText>Te creamos un plan de impacto en las redes sociales, con definición de mercado, alcance y estimaciones, con la opción de adicionalmente crear contenido afin a tu negocio, para tus redes sociales, anuncios de marketing, uso empresarial o como requieras.</CardText>
                        <Button onClick={props.botonMarketing} style={estiloBoton}>¡Obten nuestras ofertas!</Button>
                        </CardBody>
                    </Card>}

                    {!appsDescripcion &&<Button onClick={abrirDescripcionApps} color="light" className="boton-sombra">
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Una app para tu negocio</CardSubtitle>
                        <img  width={"250vw"} src="https://media1.tenor.com/images/786e6c1d63b24d53a872f467c81547e8/tenor.gif?itemid=14118662"/>
                        <CardSubtitle style={{fontSize:"8px"}} className="mb-2 text-muted"><a href="https://tenor.com/view/applantic-code-coders-programmer-developer-gif-14118662">GIF</a> de <a href="https://tenor.com/search/applantic-gifs">tenor.com</a></CardSubtitle>
                    </Button>}
                    {appsDescripcion && <Card style={estiloFondoCarta}>
                        <CardImg top width="100%" src="https://media1.tenor.com/images/786e6c1d63b24d53a872f467c81547e8/tenor.gif?itemid=14118662" alt="Card image cap" />
                        <CardBody>
                        <CardTitle tag="h5">Aplicaciones Móviles y web</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">¿Tienes una idea o una necesidad que resolver digitalmente?</CardSubtitle>
                        <CardText>Creamos tu MVP, sistema de gestión, presentaciones interactivas, ecommerce o plataforma digital que requieras.</CardText>
                        <Button onClick={props.botonAplicativo} style={estiloBoton}>¡Enterate de nuestras ofertas!</Button>
                        </CardBody>
                    </Card>}

                    {!pedidoDescripcion &&<Button onClick={abrirDescripcionPedidos} color="light" className="boton-sombra">
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Agendamiento virtual automático</CardSubtitle>
                        <img  width={"250vw"} src='https://media1.tenor.com/images/96e6885fdbae5087e60c3a9a07db85a5/tenor.gif?itemid=20850575'/>
                        <CardSubtitle style={{fontSize:"8px"}} className="mb-2 text-muted"><a href="https://tenor.com/view/android-developer-gif-20850575">GIF</a> de <a href="https://tenor.com/search/androiddeveloper-gifs">tenor.com</a></CardSubtitle>
                    </Button>}
                    {pedidoDescripcion&&<Card style={estiloFondoCarta}>
                        <CardImg top width="100%" src='https://media1.tenor.com/images/96e6885fdbae5087e60c3a9a07db85a5/tenor.gif?itemid=20850575' alt="Card image cap" />
                        <CardBody>
                        <CardTitle tag="h5">Agendamiento virtual automático</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Todas las mesas, desde la palma de tu mano, todo tu inventario o servicio al alcance de tu cliente</CardSubtitle>
                        <CardText>Configuramos tu agenda virtual que atienda a las solicitudes y tiempo de tus clientes acorde a tu horario de disponibilidad, permite que otras personas aparten cita en tu consultorio facilmente, solo necesitas acceso a internet.</CardText>
                        <Button style={estiloBoton} onClick={props.botonPedidos}>¡Interactua con un ejemplo aquí!</Button>
                        </CardBody>
                    </Card>}
                </CardDeck>
                </Col>
            </Row>
            <br/>
            <Row>
                
            <Col>
                <CardDeck >
                     {!formDescripcion &&<Button onClick={abrirDescripcionForm} color="light" className="boton-sombra">
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Diseño de formularios e <br/>interfaces gráficas personalizadas</CardSubtitle>
                        <img  width={"250vw"} src='https://www.roboform.com/images/backgrounds/rf-formfiller-animation.gif'/>
                        <CardSubtitle style={{fontSize:"8px"}} className="mb-2 text-muted"> GIF de <a href="https://www.roboform.com/es/form-filler">roboform.com</a></CardSubtitle>
                    </Button>}
                    
                    {formDescripcion && <Card style={estiloFondoCarta}>
                        <CardImg top width="100%" src="https://www.roboform.com/images/backgrounds/rf-formfiller-animation.gif" alt="Card image cap" />
                        <CardBody>
                        <CardTitle tag="h5">Diseño e implementación de Formularios e interfaces gráficas a tu medida</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Personalizamos tus formularios digitales</CardSubtitle>
                        <CardText>Diseñamos tu interaz gráfica con la mejor estética, pruebas de usabilidad y apropiada interaccion con los usuarios, tambien configuramos los formularios según tus requerimientos, para que se acople a tu sistema de gestión, envíe la información a un endpoint, a un email y/o se descargue en formato csv o json</CardText>
                        <Button style={estiloBoton} onClick={props.botonDisenho}>¡Interactua con un ejemplo aquí!</Button>
                        </CardBody>
                    </Card>}
                    {!botDescripcion &&<Button onClick={abrirDescripcionBot} color="light" className="boton-sombra">
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Auto-chats y toma-pedidos <br/>virtual para tus clientes</CardSubtitle>
                        <img width={"250vw"} src='https://cliply.co/wp-content/uploads/2019/05/371905140_MEET_ROBOT_400px.gif'/>
                        <CardSubtitle style={{fontSize:"8px"}} className="mb-2 text-muted"><a href="https://cliply.co/clip/robot-waving/">GIF</a> de <a href="https://cliply.co/clip">cliply.co</a></CardSubtitle>
                    </Button>}
                    {botDescripcion &&
                        <Card style={estiloFondoCarta}>
                        <CardImg top width="100%" src='https://cliply.co/wp-content/uploads/2019/05/371905140_MEET_ROBOT_400px.gif' alt="Card image cap" />
                        <CardBody>
                        <CardTitle tag="h5">Auto-chats y toma-pedidos para conectar con tus clientes facilmente</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Con tu chat automatizado podrás responder a tus clientes rapidamente y a la mano lo que necesiten </CardSubtitle>
                        <CardText>Automatiza el paso a paso de recetas, consultas, menu de restaurantes, pedidos de tu inventario, planes comerciales de tu servicio o producto, soporte, opciones de productos personalizables y ¡muchos casos mas!</CardText>
                        <Button onClick={props.botonChatBot} style={estiloBoton}>¡Cuéntanos cómo podríamos ayudarte!</Button>
                        </CardBody>
                        </Card>
                    }
                    {!traductorDescripcion &&<Button onClick={abrirDescripcionTraductor} color="light" className="boton-sombra">
                        <CardSubtitle tag="h6" className="mb-2 text-muted">¿Necesitas traducir tu app?</CardSubtitle>
                        <img width={"250vw"} src='https://cliply.co/wp-content/uploads/2021/02/392102850_EARTH_EMOJI_400px.gif'/> 
                        <CardSubtitle style={{fontSize:"8px"}} className="mb-2 text-muted"><a href="https://cliply.co/clip/earth-emoji/">GIF</a> de <a href="https://cliply.co/clip">cliply.co</a></CardSubtitle>  
                                      
                    </Button>}
                    
                    {traductorDescripcion&&<Card style={estiloFondoCarta}>
                        <CardImg top width="100%" src='https://cliply.co/wp-content/uploads/2021/02/392102850_EARTH_EMOJI_400px.gif' alt="Card image cap" />
                        <CardBody>
                        <CardTitle tag="h5">¿Necesitas traducir tu app?</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Desde y hacia inglés, portugués, español o alemán...</CardSubtitle>
                        <CardText>Trabajamos con Kotlin, Java, Flutter, React JS, donde el texto que el usuario visualiza de tu aplicación lo traducimos a alguno de los idiomas mencionados.</CardText>
                        <Button onClick={props.botonTraducir} style={estiloBoton}>¡Hacemos tu cotización!</Button>
                        </CardBody>
                    </Card>}
                </CardDeck>
                </Col>
            </Row>
        </Container>
       
    )
}