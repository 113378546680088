import React from 'react';
import { Link } from 'react-router-dom';
import '../estilos/Navbar.css';
import { Container, Col, Row, Navbar, Collapse, Button,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink, } from 'reactstrap';

function NavbarMax  (props) {

  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const estiloBotones={backgroundColor: "#25e1d4", background:" linear-gradient(to right, #25e1d4,#3dc28f)",color:"#ffffff"}

  return (
    <div className="NavbarLayout">
      <Container fluid >
        
        <Row>
          <Col xs="4" md="7" lg="8">              
          <Navbar  light expand="md" >
      <NavbarToggler onClick={toggle} />
          <Nav   style={{zIndex:"25"}} className="mr-auto" navbar>
          <Collapse isOpen={isOpen} navbar>
            <NavItem >
              <Link to="/nosotros"><Button outline color="light"> ¿Quienes somos? </Button></Link>
            </NavItem>
            <NavItem>
              <Link to="/trayectoria"><Button outline color="light">  Nuestra experiencia  </Button></Link> 
            </NavItem>
            <NavItem>
              <Link to="/#servicios"><Button outline color="light"> Nuestros servicios </Button></Link>
            </NavItem>
            <NavItem>
              <Link to="/interacciones"><Button outline color="light"> Tu negocio automatizado </Button></Link>
            </NavItem>
            
          </Collapse>
          </Nav>
      </Navbar>
          </Col>
          
          <Col xs="8" md="5" lg="4">
          <div className="rcorners1">            
            <img src={process.env.PUBLIC_URL + '/logoBifrost.png'} className="logo-empresa"/>
            <p style={{fontSize:"calc(4px + 1vmin)", paddingLeft:"1vh"}}>Bifrost Solutions SAS, un portal a la digitalización.</p>
          </div >
          </Col >
        </Row>         
      </Container>
    </div>
  );
}

export default NavbarMax;
