import React from 'react';

import NavbarMax from './Navbar';
import FooterMax from './Footer';

function Layout(props) {
  // const children = props.children;

  return (
    <React.Fragment>
      <NavbarMax />
      {props.children}
      <FooterMax/>
    </React.Fragment>
  );
}

export default Layout;