import React, {useState,useEffect} from 'react';
import '../estilos/Principal.css';
import { Card, CardBody, CardTitle, CardText, Container, Col, Row, UncontrolledCarousel, Jumbotron , UncontrolledPopover, PopoverHeader, PopoverBody  } from 'reactstrap';
import Servicios from '../componentes/Servicios'
import ChatBot from 'react-simple-chatbot';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {ThemeProvider } from 'styled-components';
import {marketingChat } from '../chatbot/itemsMarketing';
import {traducirChat} from '../chatbot/itemsTraducir';
import {aplicativosChat} from '../chatbot/itemsAplicativos';
import {designChat} from '../chatbot/itemsDesign';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
} from "@fortawesome/free-brands-svg-icons"
import {
} from "@fortawesome/free-regular-svg-icons"
import {
  faStroopwafel,
} from "@fortawesome/free-solid-svg-icons"


const InfoDigital=(props)=>  {
  return(
    <div>
      <CardTitle tag="h5">Los enlaces externos a continuación informan y describen el impacto progresivo del ecosistema digital visto desde reconocidas fuentes:</CardTitle>

<CardText><br/>
      <li>
      <a href="https://www.america-retail.com/argentina/plataformas-conversacionales-y-la-interaccion-con-clientes-como-en-una-conversacion-real/" target="_blank" rel="noreferrer" >Cómo afectan las plataformas conversacionales a las empresas</a>
      </li>
      <li>
      <a href="https://www.abc.es/economia/abci-pymes-no-cogen-marketing-digital-202105030104_noticia.html" target="_blank" rel="noreferrer" >Cómo afecta el Marketing digital a las empresas</a>
      </li>
      <li>
      <a href="https://blogs.iadb.org/innovacion/es/covid-19-oportunidad-transformacion-digital-pymes/" target="_blank" rel="noreferrer" >Cómo afecta la transformación digital a las empresas</a>
      </li></CardText>
    </div>
    
  )
  
}

InfoDigital.propTypes = {
  steps: PropTypes.object,
};

InfoDigital.defaultProps = {
  steps: undefined,
};


function Principal (props)  {

  const [stateChat,setStateChat]=useState(true)
  const history = useHistory();
  const [chatSeguimiento, setChatSeguimiento]=useState([
    {
      id: '1',
      message: '¡Bienvenid@! puedes cerrar el chat y continuar navegando a través de nuestros servicios de transformación digital, cuando selecciones alguno, ¡volveremos a charlar!',
      trigger:'2',
      hideInput:true,
    },
    {
      id: '2',
      message: 'Ten en cuenta que puedes probar e interactuar con algúno de nuestros servicios haciendo click en la opción que te interese:',
      trigger:'servicios',
      hideInput:true,
    },
    {
        id: 'servicios',
        options: [
          { value: 'autoChat', label: 'Auto-chat y toma-pedidos para responder a tus clientes rapidamente y disponerles a la mano lo que necesiten.', trigger: ()=>{history.push('/interacciones#autochat')}  },
          { value: 'agenda', label: 'Agendamiento virtual automático.', trigger: ()=>{history.push('/interacciones#agendachat')}  },
          { value: 'transformacion', label: '¿Por que transformar digitalmente mi negocio?', trigger: "3"  },
        ],
        hideInput:true,
      },

      {
        id: '3',
        component: <InfoDigital />,
        end:true,
      },
      
  ])
  const [textoBienvenida, setTextoBienvenida] = useState("¡Un gusto colaborarte!");
  const[segundos, setSegundos]=useState(5)


  
  useEffect(()=> {
    setTimeout(() => {
      setStateChat(false)
    }, 1000*segundos);
        
    const parametro=window.location.hash.substr(1)
    console.log(parametro)
    parametro=="servicios" && window.scrollTo({left: 0, top: document.getElementById("descripcion").offsetTop, behavior: 'smooth'})
    },[stateChat])


  const theme = {
    background: "#f5f8fb",
    fontFamily: 'Helvetica Neue',
    headerBgColor: "linear-gradient(to right, #25e1d4,#3dc28f)",
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botFontColor: '#4a4a4a',
    userBubbleColor: '#fff',
    userFontColor: '#fff',
  };

  const marketingAccion=()=>{
    setStateChat(true)
    setChatSeguimiento(marketingChat)
    setTextoBienvenida("Impactamos tu mercado: ")
    setSegundos(1)
  }


  const TraducirAccion=()=>{
    setTextoBienvenida("Traducimos tus aplicaciones: ")
    setStateChat(true)
    setChatSeguimiento(traducirChat)
    setSegundos(1)
  }

  const AplicativoAccion=()=>{
    setTextoBienvenida("Te creamos tu aplicación: ")
    setStateChat(true)
    setChatSeguimiento(aplicativosChat)
    setSegundos(1)
  }

  
  const chatDesignAccion=()=>{
    setStateChat(true)
    setTextoBienvenida("Diseñamos tus plataformas digitales: ")
    setChatSeguimiento(designChat)
    setSegundos(1)
  }
  let seguimientoChat=chatSeguimiento

  
  const chatBotAccion=()=>{
    history.push('/interacciones#autochat') 
  }

  const TomaPedidosAccion=()=>{
    history.push('/interacciones#agendachat')
  }


    const items = [
      {
        src: process.env.PUBLIC_URL + '/assets/imagen_digital2.gif',
        key: '1'
      },
      {
        src:  process.env.PUBLIC_URL + '/assets/imagen_digital1.gif',
        key: '2'
      },
      {
        src:  process.env.PUBLIC_URL + '/assets/imagen_digital3.gif',
        key: '3'
      }
    ];

  
    return (
      <div >
          <Container fluid>
            
          <h1 className="titulote">¡Transformación digital! 🤖</h1>
          <br/>
            <Row>
              <Col>
                <div id="infoLinks"><UncontrolledCarousel items={items} /></div>
                <UncontrolledPopover trigger="focus" placement="bottom" target="infoLinks" >
                  <PopoverHeader style={{background: "#dee7d2"}}>Si quieres saber mas...</PopoverHeader>
                  <PopoverBody>Los enlaces externos a continuación informan y describen el impacto progresivo del ecosistema digital visto desde reconocidas fuentes:
                          <br/>
                          <ul>
                          <li>
                          <a href="https://www.america-retail.com/argentina/plataformas-conversacionales-y-la-interaccion-con-clientes-como-en-una-conversacion-real/" target="_blank" rel="noreferrer" >Cómo afectan las plataformas conversacionales a las empresas</a>
                          </li>
                          <li>
                          <a href="https://www.abc.es/economia/abci-pymes-no-cogen-marketing-digital-202105030104_noticia.html" target="_blank" rel="noreferrer" >Cómo afecta el Marketing digital a las empresas</a>
                          </li>
                          <li>
                          <a href="https://blogs.iadb.org/innovacion/es/covid-19-oportunidad-transformacion-digital-pymes/" target="_blank" rel="noreferrer" >Cómo afecta la transformación digital a las empresas</a>
                          </li>
                          </ul>
                  </PopoverBody>
                  
                  </UncontrolledPopover>
              </Col>
            </Row>
          </Container> 
          <br></br>
          <Jumbotron style={{background: "#dee7d2"}} id="descripcion">
            <p className="lead">Te brindamos las más novedosas herramientas para que tu negocio comience a andar en automático y de forma más eficaz. 👾</p>
            <hr className="my-2" />
            <p>Servicios para, pequeñas, micro y medianas empresas que quieran ser impulsadas mediante la transformación digital. Click en alguno de nuestros servicios y, ¡Comencemos!🚀</p>
            
          </Jumbotron>
          <br></br>
          <Servicios botonMarketing={marketingAccion} botonChatBot={chatBotAccion} botonPedidos={TomaPedidosAccion} botonTraducir={TraducirAccion} botonAplicativo={AplicativoAccion} botonDisenho={chatDesignAccion}> </Servicios>
          <br></br>
          <ThemeProvider theme={theme}>
          {!stateChat && <ChatBot
            steps={seguimientoChat}
            floating={true}
            placeholder="En esta zona puedes escribir..."
            floatingIcon={<FontAwesomeIcon icon={faStroopwafel} size="4x"/>}
            floatingStyle={{color:"#dee7d2aa", background:"linear-gradient(to right, #25e1d4,#3dc28f)"}}
            headerTitle={textoBienvenida}
            botAvatar={process.env.PUBLIC_URL + '/robot.png'}
            bubbleStyle={{background:"linear-gradient(to right, #25e1d4,#3dc28f)"}}
            bubbleOptionStyle={{background: "#dee7d2aa"}}
            opened={true}
            hideUserAvatar={true}
          />}
          </ThemeProvider>
      </div>
      
    );

}

export default Principal;
