import React from 'react';
import PropTypes from 'prop-types';
import Links from '../minicomponentes/Links'

const CostoApp = (props) => {
    const { steps } = props;
    const plataforma = steps.plataforma?steps.plataforma.value:'';
    const colores = steps.colores?steps.colores.value:'';
    const logo = steps.logo?steps.logo.value:'';
    const disenho = steps.disenho?steps.disenho.value:'';
    const animar = steps.animar?steps.animar.value:'';
    let valor1=0
    let valor2=0
    let valor3=0
    let valor4=0
    let valor5=0
  
    switch (plataforma) {
        case "movil":
          valor1=1.2
          break
        case "escritorio":
          valor1=1
          break
        case 'web':
        valor1=1.1
          break
        case 'movil y web':
        valor1=2
          break

        case  'escritorio, movil y web':
            valor1=2.5
          break
        default:
            valor1=0
          break
      }

      switch (colores) {
        case "si":
          valor2=5
          break
        case "no":
          valor2=50
          break
        default:
            valor2=0
          break
      }
      switch (logo) {
        case "si":
            valor3=5
            break
        case "no":
          valor3=50
          break
        default:
            valor3=0
          break
      }
      switch (disenho) {
        case "si":
            valor4=10
            break
        case "no":
          valor4=100
          break
        default:
            valor4=0
          break
      }
      switch (animar) {
        case "si":
            valor5=50
            break
        case "no":
          valor5=5
          break
        default:
            valor5=0
          break
      }
    const valorf1=valor1*(valor1+valor2+valor3+valor4+valor5)
    const valorf2=valor1*(valor2+valor3+valor4)
  
    return (
      <div className="test">
        Tu selección fue...
        <br/>
        plataforma(s): {plataforma}
        <br/>
        Tienes definidos los colores: {colores}
        <br/> 
        Tienes definido el logo: {logo}
        <br/>
        Tienes definido el patrón de diseño: {disenho}
        <br/> 
        Solicitas integrar animaciones: {animar}
        <br/>
        <br/>
        El precio por tu diseño sería desde: <b>${valorf1}  USD</b>
        <br/>
        {(colores==="no" || logo==="no" || disenho==="no") && <div>Además te podemos brindar asesoramiento para la creación de las propiedades gráficas de colores, logos y patrón de diseño desde: <b>${valor2}  USD/hora</b></div>}
        <br/>
        El precio podría incrementar en base a la complejidad 
        del diseño y las funciones anexas que se requieran
      </div>
    );
  };
  
  CostoApp.propTypes = {
    steps: PropTypes.object,
  };
  
  CostoApp.defaultProps = {
    steps: undefined,
  };


export const designChat=[
    {
      id: '1',
      message: '¿Qué tipo de diseño requieres?',
      trigger: 'plataforma',
      hideInput:true,
    },
    {
        id: 'plataforma',
        options: [
          { value: "movil", label: 'Solamente para apps Celulares', trigger: '2' },
          { value: "escritorio", label: 'Solamente para apps de escritorio', trigger: '2' },
          { value: "web", label: 'Solamente diseño web', trigger: '2' },
          { value: "movil y web", label: 'Diseños para apps móviles y para web (Aplican descuentos ✨)', trigger: '2' },
          { value: "escritorio, movil y web", label: 'Diseños para apps móviles, de escritorio y web (Aplican descuentos ✨)', trigger: '2' },
        ],
        hideInput:true,
      },
    {
      id: '2',
      message: '¿Tu diseño tiene colores definidos?',
      trigger: 'colores',
      hideInput:true,
    },
    {
        id: 'colores',
        options: [
          { value: "si", label: 'Si', trigger: '3' },
          { value: "no", label: 'No', trigger: '3' },
        ],
        hideInput:true,
      },
    {
        id: '3',
        message: '¿Tu diseño tiene logo definido?',
        trigger: 'logo',
        hideInput:true,
    },
    {
        id: 'logo',
        options: [
          { value: "si", label: 'Si', trigger: '4' },
          { value: "no", label: 'No', trigger: '4' },
         ],
        hideInput:true,
      },
    {
        id: '4',
        message: '¿Tienes algún patrón de diseño definido?',
        trigger: 'disenho',
        hideInput:true,
    },
    {
        id: 'disenho',
        options: [
          { value: "si", label: 'Si.', trigger: '5' },
          { value: "no", label: 'No.', trigger: '5' },
        ],
        hideInput:true,
      },
    {
        id: '5',
        message: '¿Tu diseño requiere animaciones?',
        trigger: 'animar',
        hideInput:true,
      },
    {
        id: 'animar',
        options: [
          { value: "si", label: 'Si.', trigger: '6' },
          { value: "no", label: 'No.', trigger: '6' },
        ],
        hideInput:true,
     },
    {
      id: '6',
      component: <CostoApp />,
      trigger: "7",
      hideInput:true,
    },
    {
      id: '7',
      hideInput:true,
      message: '¿Quieres contactarnos o prefieres realizar otra cotización?, escribe solo la palabra "contactar" o "cotizar".',
      trigger: "contacto",
   },
   {
      id: 'contacto',
      user: true,
      validator: (value) => {
        if (value=="Cotizar"|| value=="cotizar") {
        }
        else if(value=="Contactar" || value=="contactar"){
        }
        else{
          return 'Solo válido: cotizar o contactar';
        }
        return true;
      },
      trigger: ({ value, steps }) => {
        if(value=="contactar" || value=="Contactar")
        {
          return '8'
        }else{
          return '1'
        }}
    },
    {
      id: '8',
      hideInput:true,
      component: <Links />,
      end: true,
    },
  ]