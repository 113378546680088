import React from 'react';
import { Container, Col, Row, UncontrolledAlert, Alert, ButtonToolbar, ButtonGroup,  Card, CardDeck, CardTitle, CardText, CardImg } from 'reactstrap';
import "../estilos/nosotros.css"
import "../estilos/servicios.css"
import {Accordion, AccordionCollapse, AccordionToggle, Button} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    faLinkedin
} from "@fortawesome/free-brands-svg-icons"


function Nosotros() {
  return (
    <div>
        <UncontrolledAlert color="success">
        ¡Da click en algúna de las fotos para más detalles!
      </UncontrolledAlert>
        <Container fluid>
        <Row>
          <Col xs={{ size: 12, offset: 0 }}>

          <h1 className="titulote">BIFROST SAS SOLUTIONS</h1>
          
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 12, offset: 0 }}>

          <h2 className="subtitulote">Un equipo, una empresa</h2>
          
          </Col>
        </Row>
        

        <Row>
          <Col >

          <CardDeck>
            <Card style={{borderStyle: "none"}}  >

            <img className="image-perfil1" src="https://media-exp1.licdn.com/dms/image/C4E03AQEdluwxPOHFWQ/profile-displayphoto-shrink_800_800/0/1618892430526?e=1630540800&v=beta&t=UCwYp-6Rg75H7SRt3KkKEfK14t_aLB4tSNjLho6fI9Q"/>
            <div className="detalles1">
            <p className="text-titulo">Stiven Diaz</p>
            <h2 className="text-descrip"><b>Architect & Software developer.</b>
            <br/> Arquitecto de la lógica de negocio que se implementa en los distintos aplicativos, intragando el backend con el frontend, diseñador de
            los modelos de datos que se integran y las tecnologías adecuadas para cada proyecto. Analista de datos que se brindan desde Google analytics 
            en sus integraciones con los diferentes proyectos. 
            </h2>
            </div>
            <Accordion className="acordeon-perfil" style={{marginLeft:"10%", width:"80%"}}>
            <Card className="contenedor1">
            <CardImg top width="100%" src={process.env.PUBLIC_URL + '/assets/digitalTools1.jpg'} alt="desarrollador" />
               
                <AccordionToggle as={Button} variant="success" eventKey="0" className="boton-sombra">
                  Perfil profesional
                </AccordionToggle>
              <AccordionCollapse eventKey="0" className="fondo-opaco">
                <CardText>Programador y líder de tecnología que usa herramientas actuales afines a los diferentes desarrollos
                que se tienen. Gran afinidad hacia el desarrollo de aplicativos móviles y el frontend de los aplicativos web, 
                siendo el enargado de las integraciones entre bases de datos y servidores con la interfaz presentada a los usuarios.
                Tiene un importante enfoque en la ejecución de la transformación digital de los negocios.
                Habla con fluidez el idioma Portugués. 
                 </CardText>
              </AccordionCollapse>
                <AccordionToggle as={Button} variant="info" eventKey="1" className="boton-sombra">
                  Habilidades
                </AccordionToggle>
              <AccordionCollapse eventKey="1" className="fondo-opaco">
              <div> <CardTitle className="text-descrip">Funciones y características</CardTitle>
                    
                    <Alert color="secondary">
                    Liderazgo y arquitectura en soluciones de software.</Alert>
                    <Alert color="secondary">
                    Implementación de las tecnologías necesarias para conseguir con eficiencia los diferentes desarrollos. </Alert>
                    <Alert color="secondary">
                    Sentido de análisis para abstraer información valiosa de los datos que se recoleten.</Alert>
                    <br/>
                    </div>
               
              </AccordionCollapse>
              <AccordionToggle as={Button} variant="warning" eventKey="2" className="boton-sombra">
                  Contacto
                </AccordionToggle>
              <AccordionCollapse eventKey="2" className="fondo-opaco">
              <Container fluid >
                <br/>
                <Row>
                
                <Col xs="4" md={{ size: '3', offset: 1 }} lg={{ size: '3', offset: 1 }}>          
                    <a href="https://www.freelancer.es/u/Stivendiaz14" target="_blank" rel="noreferrer">
                        <Button outline  color="secondary">
                            <img src="https://www.f-cdn.com/assets/main/en/assets/default-notification-image.svg" width="30vh"/>                       
                        </Button>
                    </a>
                </Col>
                <Col xs="4" md={{ size: '3', offset: 1 }} lg={{ size: '3', offset: 1 }}> 
                    <a href="https://platzi.com/p/stiven-diaz402/" target="_blank" rel="noreferrer" >
                        <Button outline  color="light">
                            <img src="https://static.platzi.com/commons/assets/image/isotipoPlatzi442ccc1186a9806e18c9889cc301ffe1.png" width="30vh"/>
                        </Button>
                    </a>
                </Col>
                <Col xs="4" md={{ size: '3', offset: 1 }} lg={{ size: '3', offset: 1 }}> 
                    <a href="https://www.linkedin.com/in/stiven-diaz-63432a191" target="_blank" rel="noreferrer" >
                        <Button outline  color="light">
                            <FontAwesomeIcon icon={faLinkedin} size="2x"/>
                        </Button>
                    </a>
                </Col >
                </Row>   
                <br/>      
            </Container>
                
              </AccordionCollapse>
            </Card>
          </Accordion>
            </Card>


            <Card style={{borderStyle: "none"}} >
            <img className="image-perfil2" src="https://media-exp1.licdn.com/dms/image/C5603AQFGTGBSfRRTGA/profile-displayphoto-shrink_200_200/0/1613597102995?e=1630540800&v=beta&t=DcYzWZmmUKCAVqiciPIDpdkWL26OfcvCwkxgXmt4CBg"/>
        <div className="detalles2">
        <p className="text-titulo">David Galvis</p>
        <h2 className="text-descrip"><b>UI/UX designer, developer & digital marketer.</b>
        <br/> Creador de los diseños que mejor se adecuan a los requerimientos de los proyectos para tener los mejores resultados de usabilidad e 
        interacción de las interfaces de usuario por parte de los mismos. Creador de contenido para redes sociales y encargado de realizar labores
        de mercadeo digital según el estudio de los nichos de mercado a los que apliquen los proyectos. </h2>
        </div>
        <Accordion className="acordeon-perfil" style={{marginLeft:"10%", width:"80%"}}>
            <Card className="contenedor2">
            <CardImg top width="100%" src={process.env.PUBLIC_URL + '/assets/digitalTools2.jpg'} alt="desarrollador" />
               
                <AccordionToggle as={Button} variant="success" eventKey="0" className="boton-sombra">
                  Perfil profesional
                </AccordionToggle>
              <AccordionCollapse eventKey="0" className="fondo-opaco">
                <CardText>Diseñador, desarrollador y lider de mercadeo, con gran afinidad hacia la eficiencia y la acertitud en los objetivos que se plasmen para cada proyecto. Posee una visión destacable
                hacia el orden de los componentes visuales y su aspecto estético. Acomoda los potenciales usuarios acorde las distinciones de cada negocio, con estrategias y planificaciones 
                que apuntan acertivamente con el uso de herramientas de outbound e inbound marketing. Habla con fluidez el idioma inglés.  </CardText>
              </AccordionCollapse>
                <AccordionToggle as={Button} variant="info" eventKey="1" className="boton-sombra">
                  Habilidades
                </AccordionToggle>
              <AccordionCollapse eventKey="1" className="fondo-opaco">
              <div> <CardTitle className="text-descrip">Funciones y características</CardTitle>
                    
                    <Alert color="secondary">
                    Planificación y ejecución en estrategias de mercadeo.</Alert>
                    <Alert color="secondary">
                    Liderazgo y guía para labores de soporte y negociación. </Alert>
                    <Alert color="secondary">
                    Sentido de estética y organización en elementos gráficos.</Alert>
                    <br/>
                    </div>
               
              </AccordionCollapse>
              <AccordionToggle as={Button} variant="warning" eventKey="2" className="boton-sombra">
                  Contacto
                </AccordionToggle>
              <AccordionCollapse eventKey="2" className="fondo-opaco">
                <Container fluid >
                <br/>
                <Row>
                <Col xs="6" md={{ size: '3', offset: 2 }} lg={{ size: '3', offset: 2 }}> 
                    <a href="https://platzi.com/p/davidgalvisr97/" target="_blank" rel="noreferrer" >
                        <Button outline  color="light">
                            <img width="30vh" src="https://static.platzi.com/commons/assets/image/isotipoPlatzi442ccc1186a9806e18c9889cc301ffe1.png"/>
                        </Button>
                    </a>
                </Col>
                <Col xs="6" md={{ size: '3', offset: 2 }} lg={{ size: '3', offset: 2 }}> 
                    <a href="https://www.linkedin.com/in/oscar-david-galvis-rodriguez-680273108/" target="_blank" rel="noreferrer" >
                        <Button outline  color="light">
                            <FontAwesomeIcon icon={faLinkedin} size="2x"/>
                        </Button>
                    </a>
                </Col >
                </Row>    
                <br/>     
            </Container>
              </AccordionCollapse>
            </Card>
          </Accordion>
            </Card>
        </CardDeck>
          </Col>
        </Row>

        
      </Container>
      
    </div>);
}

export default Nosotros;