import React, {useState, useEffect} from 'react';
import '../estilos/Navbar.css';
import { Container, Col, Row,  ButtonGroup, Button, ButtonToolbar, Card} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
  faWhatsapp,
  faLinkedin,
  faTelegram,
}from "@fortawesome/free-brands-svg-icons"
import { Link } from 'react-router-dom';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';


  export default function FooterMax(props) {
      

  return (
      <div className="FooterLayout">
        <Container fluid >
                <Row>
                    <Col xs="6" md={{ size: '6', offset: 4 }} lg={{ size: '6', offset: 5 }}>
                    BIFROST SOLUTIONS SAS / NIT 901522337-5 / BOGOTÁ DC </Col>
                </Row>
                <Row>
                    <Col xs="6" md={{ size: '6', offset: 4 }} lg={{ size: '6', offset: 5 }}>
                    Contáctanos en: </Col>
                </Row>
                <Row>
                
                <Col xs="6" md={{ size: '6', offset: 4 }} lg={{ size: '6', offset: 5 }}>
                <div >            
                <ButtonToolbar>
                    
                    <ButtonGroup>
                    <a href={`https://api.whatsapp.com/send?phone=573028468860&text="Hola. Estoy interesado en obtener un plan de transformación digital para mi negocio..."`} target="_blank" rel="noreferrer">
                        <Button outline  color="light">
                            <FontAwesomeIcon icon={faWhatsapp} size="2x"/>
                        </Button>
                    </a>
                    <a href="https://t.me/bifrost_apps" target="_blank" rel="noreferrer" >
                        <Button outline  color="light">
                            <FontAwesomeIcon icon={faTelegram} size="2x"/>
                        </Button>
                    </a>
                    {/* <a href="https://www.instagram.com/rosegold.ice/" target="_blank" rel="noreferrer" >
                        <Button outline  color="light">
                            <FontAwesomeIcon icon={faLinkedin} size="2x"/>
                        </Button>
                    </a> */}
                    <a href="mailto:bifrost.aplicaciones@gmail.com?subject=Plan de transformación digital&body=Hola. Estoy interesado en obtener un plan de transformación digital para mi negocio..." target="_blank" rel="noreferrer" >
                        <Button outline  color="light">
                            <FontAwesomeIcon icon={faMailBulk} size="2x"/>
                        </Button>
                    </a>
                    </ButtonGroup>
                    </ButtonToolbar>
                </div >
                </Col >
                </Row>         
            </Container>
      </div>

  );
}
