import React, {useEffect, useState} from 'react';
import '../estilos/Principal.css';
import { Container, Col, Row, Card, List, CardText, CardTitle, CardSubtitle, Button, Badge, ButtonDropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem, UncontrolledAlert} from 'reactstrap';
import {ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {AutoChat} from '../chatbot/chatAuto';
import {setNegocio, setFuncion, setOpcionesR, setPedidosAdmin, setAdminModo} from '../actions/index';
import {connect} from 'react-redux';
import {
  faStroopwafel,
  faArrowCircleLeft
} from "@fortawesome/free-solid-svg-icons"

import moment from 'moment';
import WeekCalendar from 'react-week-calendar';
import CustomHeaderCell from './calendario/CustomHeaderCell';
import CustomDayCell from './calendario/CustomDayCell';
import CustomModal from './calendario/CustomModal';
import CustomEvent from './calendario/CustomEvent';
import './calendario/customCalendar.less'
import 'react-week-calendar/dist/style.css';


function Interacciones(props) {

  const estiloBoton={background:"linear-gradient(to right, #25e1d4,#3dc28f)"}
  const theme = {
    background: "#f5f8fb",
    fontFamily: 'Helvetica Neue',
    headerBgColor: "linear-gradient(to right, #25e1d4,#3dc28f)",
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botFontColor: '#4a4a4a',
    userBubbleColor: '#fff',
    userFontColor: '#fff',
  };


  const [stateChat,setStateChat]=useState(true)
  const [btnDropUser,setBtnDropUser]=useState(false)
  const [primeraVez,setPrimeraVez]=useState(true)
  const [modal, setModal] = useState(false);
  const [calendario,setCalendario]=useState({
    lastUid: 1,
    selectedIntervals: []
  })
  const [opcionesVisibles,setOpcionesVisibles]=useState(true)
  //const [btnDropNegocio,setBtnDropNegocio]=useState(false)
  const [usuario,setUsuario]=useState("cliente")
  //const [negocioActual, setNegocioActual]=useState([])
  const [registros,setRegistro]=useState([])
  const [horaRegistro,setHoraRegistro]=useState([])
  const [totalizado, setTotalizado]=useState([])
  const [totalizadoActual, setTotalizadoActual]=useState(0)
  const [chatSeguimiento, setChatSeguimiento]=useState([
    {
      id: '1',
      message: 'Puedes cerrar esta ventana y seleccionar alguno de los ejemplos que disponemos para diferentes tipos de negocio... Recuerda que podremos acoplar el chat automatizado o agendamiento virtual a tu negocio, comunícate con nosotros y te comentamos en que manera lo podremos realizar.',
      hideInput:true,
      end:true
    },
      
  ])
  const [textoBienvenida, setTextoBienvenida] = useState("¡Interactúa con nuestros ejemplos!");

  useEffect(()=> {
    setTimeout(() => {
      setStateChat(false)
    }, 1000);
        
    const parametro=window.location.hash.substr(1)
    console.log(parametro, props.negocioo, props.restaurantee)
    Object.entries(props.restaurantee.entradas).map(([key, value], i) => {console.log(key,value)
  }) 
    if(primeraVez){
      switch(parametro){
        case "autochat":
          props.setFuncion("vendedor")
          props.setNegocio("negocio2")
          setChatSeguimiento(AutoChat({negocioDet:"negocio2", funcion:"vendedor"}))
          //setNegocioActual(["Granizados", "Restaurante"])
          setOpcionesVisibles(false)
          setTextoBienvenida("Tu marca / Saludo")
          break;
        case "agendachat":
          props.setFuncion("agenda")
          props.setNegocio("negocio2")
          setChatSeguimiento(AutoChat({negocioDet:"negocio2", funcion:"agenda"}))
          //setNegocioActual(["Peluqueria", "Consultorio odontológico"])
          setOpcionesVisibles(false)
          setTextoBienvenida("Tu marca / Saludo")
          break;
        default:
          setOpcionesVisibles(true)
          break;
    }
    }
    },[stateChat])

    const chatVenta=()=>{
      props.setFuncion("vendedor")      
      props.setNegocio("negocio2")
      //setNegocioActual(["Granizados", "Restaurante"])
      setPrimeraVez(false)
      setOpcionesVisibles(false)
      setStateChat(true)
      setChatSeguimiento(AutoChat({negocioDet:"negocio2", funcion:"vendedor"}))
      setTextoBienvenida("Tu marca / Saludo")

    }
  
    const agendamiento=()=>{
      props.setFuncion("agenda")
      props.setNegocio("negocio2")
      //setNegocioActual(["Peluqueria", "Consultorio odontológico"])
      setPrimeraVez(false)
      setOpcionesVisibles(false)
      setStateChat(true)
      setChatSeguimiento(AutoChat({negocioDet:"negocio2", funcion:"agenda"}))
      setTextoBienvenida("Tu marca / Saludo")
    }
  
    let seguimientoChat=chatSeguimiento

    const dropDownUser=(tipo)=>{
      console.log(tipo)
      setUsuario(tipo)
      tipo=="administrador"?props.setAdminModo(true):props.setAdminModo(false)
    }

   /*  const dropDownNegocio=(tipo)=>{
      
      setStateChat(true)
      props.setNegocio(tipo)
      console.log("clickeado")
      console.log(tipo)
      setChatSeguimiento(AutoChat({negocioDet:tipo, funcion:props.funcioon}))
    } */


    const registrarAdmin=()=>{
      const array=registros
      const hora= new Date().toLocaleString()
      const arrayHora=horaRegistro
      const totalArray=totalizado
      const arrayEstado=props.pedidosEstado
      let total=0
      Object.entries(props.restaurantee.entradas).map(([key, value], i) => {if(key!=="comentarios") {total=total+value*props.restauranteC.entradas[key]}})
      Object.entries(props.restaurantee.fuerte).map(([key, value], i) => {if(key!=="comentarios") {total=total+value*props.restauranteC.fuerte[key]}})
      Object.entries(props.restaurantee.bebidas).map(([key, value], i) => {if(key!=="comentarios") {total=total+value*props.restauranteC.bebidas[key]}})
      setTotalizadoActual(total)
      totalArray.push(total)
      arrayHora.push(hora)
      arrayEstado.push(false)
      array.push(props.restaurantee)
      setRegistro(array)
      setTotalizado(totalArray)
      setHoraRegistro(arrayHora)
      props.setPedidosAdmin(arrayEstado)
      setStateChat(true)

  }

  const handleEstadoPedido=(cont)=>{
      let arrayEstado=props.pedidosEstado
      console.log(arrayEstado)
      arrayEstado[cont]=true
      props.setPedidosAdmin(arrayEstado)
      toggleModal()
  }

  const toggleModal = () => setModal(!modal);


    const adquisiciones=
    <div>
      <CardText>Entradas: </CardText>
        <List type="unstyled">
          <li>
          <ul>           
          {Object.entries(props.restaurantee.entradas).map(([key, value], i) => {return key==="comentarios" && value!=""?  <li key={key}>
                   Comentarios: {value} 
                  </li>: value>0 && key!=="comentarios" && <li key={key}>
                    Orden: {key} / cantidad: {value} / valor: ${value*props.restauranteC.entradas[key]}
                  </li>})}
            </ul>
          </li>
        </List>
        <CardText>Plato Fuerte: </CardText>
        <List type="unstyled">
          <li>
          <ul>           
          {Object.entries(props.restaurantee.fuerte).map(([key, value], i) => {return key==="comentarios" && value!=""?  <li key={key}>
                    Comentarios: {value} 
                  </li>: value>0 && key!=="comentarios" && <li key={key}>
                    Orden: {key} / cantidad: {value} / valor: ${value*props.restauranteC.fuerte[key]}
                  </li>})}
            </ul>
          </li>
        </List>
        <CardText>Bebidas: </CardText>
        <List type="unstyled">
          <li>
          <ul>           
          {Object.entries(props.restaurantee.bebidas).map(([key, value], i) => {return key==="comentarios" && value!=""?  <li key={key}>
                    Comentarios: {value}  
                  </li>: value>0 && key!=="comentarios" && <li key={key}>
                    Orden: {key} / cantidad: {value} / valor: ${value*props.restauranteC.bebidas[key]}
                  </li>})}
            </ul>
          </li>
        </List>
      </div>




    const handleEventRemove = (event) => {
      const {selectedIntervals} = calendario;
      const index = selectedIntervals.findIndex((interval) => interval.uid === event.uid);
      if (index > -1) {
        selectedIntervals.splice(index, 1);
        setCalendario({selectedIntervals});
      }

    }

    const handleEventUpdate = (event) => {
      const {selectedIntervals} = calendario;
      const index = selectedIntervals.findIndex((interval) => interval.uid === event.uid);
      if (index > -1) {
        selectedIntervals[index] = event;
        setCalendario({selectedIntervals});
      }
    }

    const handleSelect = (newIntervals) => {
      const {lastUid, selectedIntervals} = calendario;
      const intervals = newIntervals.map( (interval, index) => {

        return {
          ...interval,
          uid: lastUid + index
        }
      });

      setCalendario({
        selectedIntervals: selectedIntervals.concat(intervals),
        lastUid: lastUid + newIntervals.length
      })
    }

  return (<div>
    <UncontrolledAlert color="success" fade={false} style={{fontSize:"2vh"}}>
        Creamos tu sitio web usando estas funcionalidades ✨
      </UncontrolledAlert>
    <Container fluid>
      <Row>
        <Col xs={{ size: 12, offset: 0 }}>

        <h1 className="titulote">¡Liberate de la atención al cliente!</h1>
        <br/>
        </Col>    

      </Row>
      {opcionesVisibles ? 
      <div>
        <Card className="tarjeton">
        <Row>
          <Col xs={{ size: 7, offset: 0 }} md={{ size: 7, offset: 1 }} lg={{ size: 7, offset: 1 }}>
            <h3 className="text-descrip">Chat automatizado para venta de productos o servicios o para tomar pedidos, administrarlos y mostrar su factura, a los clientes automáticamente</h3>
          </Col>
          <Col xs="4" md="4" lg="4">
          <Button color="secondary" size="lg" style={estiloBoton} onClick={chatVenta}>PROBAR</Button>
          </Col>
        </Row>
        </Card>

        <Card className="tarjeton">
        <Row>
        <Col xs={{ size: 7, offset: 0 }} md={{ size: 7, offset: 1 }} lg={{ size: 7, offset: 1 }}>
            <h3 className="text-descrip">Agendamiento automático para consultorios, lavaderos de carros, instituciones académicas, alquiler de disfraces, peluquerias y cualquier tipo de negocio que requiera apartar una cita para un producto o servicio.</h3>
          </Col>
          <Col xs="4" md="4" lg="4">
          <Button color="secondary" size="lg" style={estiloBoton} onClick={agendamiento}>PROBAR</Button>
          </Col>
        </Row>
        </Card>
      </div>:
      <div>
        <Container>
        <Row>
          <Col>
          <Button color="secondary" size="md" style={estiloBoton} onClick={()=>{setOpcionesVisibles(true)}}><FontAwesomeIcon icon={faArrowCircleLeft} size="2x"/></Button>
          </Col> 
        </Row>
        <br/>
        <Row>
         
          <Col>
            <ButtonDropdown direction="right"  isOpen={btnDropUser} toggle={() => {setBtnDropUser(!btnDropUser) }}>
              <DropdownToggle size="lg" caret style={estiloBoton}>
                ¿Administrador o cliente?
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={()=>{dropDownUser("administrador")}}>Administrador/Miembro del equipo</DropdownItem>
                <DropdownItem onClick={()=>{dropDownUser("cliente")}}>cliente</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
          <Col>
          <h2 style={{color:"gray"}}>{props.funcioon=="vendedor"? "Modo Restaurante": "Modo agendamiento peluqueria"}</h2>

            {/* <ButtonDropdown direction="right"  isOpen={btnDropNegocio} toggle={() => {setBtnDropNegocio(!btnDropNegocio) }}>
              <DropdownToggle caret style={estiloBoton}>
                Tipo de negocio...
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={()=>{dropDownNegocio("negocio1")}}>{negocioActual[0]}</DropdownItem>
                <DropdownItem onClick={()=>{dropDownNegocio("negocio2")}}>{negocioActual[1]}</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown> */}
          </Col>
        </Row>
            <br/>
       {props.funcioon==="vendedor"? <Row>
          <Col>
          {usuario==="cliente"?<Card body  style={{background: "#dee7d2", marginTop:"2vh"}}>
            <CardTitle tag="h5" className="text-center">Sumario: </CardTitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted">(Recuerda que puedes acceder a los diferentes items del chat para realizar ajustes a tu pedido antes de enviarlo)</CardSubtitle>
            {adquisiciones}
            <CardTitle tag="h5" className="text-center">Valor total: <b>${totalizadoActual}</b> </CardTitle>
            <Button color="secondary" size="md" style={estiloBoton} onClick={registrarAdmin}>Enviar requerimiento y totalizar. Una vez enviado no se puede editar.</Button>
          </Card>:
          <div>
            {registros.map((item,cont)=> <Card body key={cont} style={{background: "#dee7d2", marginTop:"2vh"}}>
          <CardTitle tag="h5" className="text-center">Registros: </CardTitle>
          <CardSubtitle tag="h5" className="text-right">{horaRegistro[cont]}</CardSubtitle>
          <br/>
          <CardSubtitle tag="h6" className="mb-2 text-muted">(Este es un ejemplo de como el administrador del sistema vería sus requerimientos, ¡todo es se puede ajustar a lo que necesites visualizar!)</CardSubtitle>
          <List type="unstyled">
          <li>
          <ul>           
          {Object.entries(item.entradas).map(([key, value], i) => {return key==="comentarios" && value!=""?  <li key={key}>
                    Comentarios: {value} 
                  </li>: value>0 && key!=="comentarios" && <li key={key}>
                    Orden: {key} / cantidad: {value} / valor: ${value*props.restauranteC.entradas[key]}
                  </li>})}
            </ul>
          </li>
          </List>
          <CardText>Plato Fuerte: </CardText>
          <List type="unstyled">
            <li>
            <ul>           
            {Object.entries(item.fuerte).map(([key, value], i) => {return key==="comentarios" && value!=""?  <li key={key}>
                      Comentarios: {value} 
                    </li>: value>0 && key!=="comentarios" && <li key={key}>
                      Orden: {key} / cantidad: {value} / valor: ${value*props.restauranteC.fuerte[key]}
                    </li>})}
              </ul>
            </li>
          </List>
          <CardText>Bebidas: </CardText>
          <List type="unstyled">
            <li>
            <ul>           
            {Object.entries(item.bebidas).map(([key, value], i) => {return key==="comentarios" && value!=""?  <li key={key}>
                      Comentarios: {value} 
                    </li>: value>0 && key!=="comentarios" && <li key={key}>
                      Orden: {key} / cantidad: {value} / valor: ${value*props.restauranteC.bebidas[key]}
                    </li>})}
              </ul>
            </li>
          </List>
          
          <CardTitle tag="h5" className="text-center">Valor total: <b>${totalizado[cont]}</b> </CardTitle>
          <Col xs="4">{!props.pedidosEstado[cont] ? <Button color="danger" size="md" onClick={()=>{handleEstadoPedido(cont)}}>Marcar como listo y entregado</Button>: <Badge color="secondary">Pedido listo y entregado</Badge>}</Col>
        </Card>)}
          </div>
          }

          </Col>
        </Row>:
        <WeekCalendar
        startTime = {moment({h: 8, m: 0})}
        endTime = {moment({h: 21, m: 0})}
        scaleUnit ={30}
        scaleHeaderTitle="Horario"
        dayFormat="DD/MM"
        cellHeight = {30}
        numberOfDays= {5}
        selectedIntervals = {calendario.selectedIntervals}
        onIntervalSelect = {handleSelect}
        onIntervalUpdate = {handleEventUpdate}
        onIntervalRemove = {handleEventRemove}
        dayCellComponent={CustomDayCell}
        modalComponent={CustomModal}
        eventComponent={CustomEvent}
      />}

        </Container>

        <br/>
        <br/>
      </div>
      }
      
    </Container>

    <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Aqui puedes colocar un mensaje para la acción del administrador</ModalHeader>
        <ModalBody>
          El sistema puede ser tan flexible como requieras, contáctanos y comentanos qué otras funcionalidades solicitarías integrar.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Cerrar</Button>
        </ModalFooter>
      </Modal>

    <ThemeProvider theme={theme}>
          {!stateChat && <ChatBot
            steps={seguimientoChat}
            floating={true}
            placeholder="En esta zona puedes escribir..."
            floatingIcon={<FontAwesomeIcon icon={faStroopwafel} size="4x"/>}
            floatingStyle={{color:"#dee7d2aa", background:"linear-gradient(to right, #25e1d4,#3dc28f)"}}
            headerTitle={textoBienvenida}
            botAvatar={process.env.PUBLIC_URL + '/robot.png'}
            bubbleStyle={{background:"linear-gradient(to right, #25e1d4,#3dc28f)"}}
            bubbleOptionStyle={{background: "#dee7d2aa"}}
            opened={true}
            hideUserAvatar={true}
          />}
          </ThemeProvider>
    
  </div>)


            
}

const mapStateToProps = state => {
  return{
    negocioo:state.negocio,
    funcioon:state.funcion,
    restaurantee:state.opcionesRestaurante,
    restauranteC:state.costosRestaurante,
    pedidosEstado:state.pedidosAdmin
  }
  
}

const mapDispatchToProps = {
  setNegocio,
  setFuncion,
  setOpcionesR,
  setPedidosAdmin,
  setAdminModo,
}

export default connect(mapStateToProps, mapDispatchToProps)(Interacciones);