const reducer = (state, action) => {
    switch(action.type){
        case 'SET_NEGOCIO':
            return {
                ...state,
                negocio: action.payload
            }

        case 'SET_FUNCION':
            return {
                ...state,
                funcion: action.payload
            }
        case 'SET_OPCIONES_G':
            return {
                ...state,
                opcionesGranizado: action.payload
            }
        case 'SET_OPCIONES_R':
            return {
                ...state,
                opcionesRestaurante: action.payload
            }
        case 'SET_PEDIDOS_ADMIN':
            return {
                ...state,
                pedidosAdmin: action.payload
            }
            
        case 'SET_MODO_ADMIN':
            return {
                ...state,
                adminModo: action.payload
            }
        case 'SET_ANALYTICS':
            return {
                ...state,
                analyticsAux: action.payload
            }
        default:
            return state;
    }
    
}

export default reducer;