import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Links from '../minicomponentes/Links'
import ObjetoNegocio from '../minicomponentes/ObjetoNegocio'




export function AutoChat(props){

  const itemsVendedor=[
    {
      id: '0',
      message: props.negocioDet=="negocio1"?"Bienvenida/o, ¡tenemos una oferta increible de cajitas sorpresa personalizables para ti! Escoje qué tipo de cajitas preferirías: ": "Bienvenida/o al Restaurante Bifrost, tenemos una variedad de comidas para ti, elige que quieres comenzar a pedir: ",
      trigger: 'entrada1',
      hideInput:true,
    },
    {
        id: 'entrada1',
        hideInput:true,
        options: props.negocioDet=="negocio1"?[
          { value: 'desayuno', label: 'Cajita sopresa para desayuno', trigger: '1' },
          { value: 'cumple', label: 'Cajita sorpresa para cumpleaños', trigger: '1' },
          { value: 'ellas', label: 'Cajita sorpresa para ellas', trigger: '1' },
          { value: 'ellos', label: 'Cajita sorpresa para ellos', trigger: '1' },
          { value: 'otro', label: 'Cajita sorpresa única', trigger: '1' }
        ]:[
          { value: 'entradas', label: 'Entradas', trigger: '1' },
          { value: 'plato fuerte', label: 'Platos fuertes', trigger: '1' },
          { value: 'bebidas', label: 'Bebidas', trigger: '1' },
        ],
      },
    {
      id: '1',
      message: props.negocioDet=="negocio1"?"Podrás elegir entre distintos tipos e ingredientes, ¿Cúal(es) prefieres?":"A continuación te presentamos las opciones disponibles de {previousValue}: ",
      trigger: 'objetoNegocio',
      hideInput:true,
    },
    {
        id: 'objetoNegocio',
        hideInput:true,
        component: <ObjetoNegocio />,
        waitAction: true,        
        replace:true,
        trigger: "2"
    },
    {
      id: '2',
      hideInput:true,
      message: props.negocioDet=="negocio1"?"¿Deseas alguna otra caja sorpresa?":"¿Deseas acceder a otras opciones de menú o editar alguna que has realizado? Para enviar tu pedido, minimiza el chat y cliquea el botón de enviar requerimiento.",
      trigger: 'entrada1',
    },

  ]


  const itemsAgenda=[
    {
      id: '1',
      message: 'Bienvenido a la barberia Ragnar, aquí establecemos un horario para que apartes en las horas disponibles el servicio que desees, cierra este chat y elige en el que estás interesado/a',
      end: true,
      hideInput:true,
    },

  ]


  switch(props.funcion){

    case "vendedor":
      return(itemsVendedor)
    case "agenda":
      return(itemsAgenda)
    default:
      break

  }
    
}
